import * as React from "react";
import { Breadcrumbs, Typography, Stack } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "assets/styles/statistics.css";

export default function TiltleCrumbsFour() {
  const { partyCode, module, tabName, btnName } = useSelector(
    (state) => state.trackreconciliation
  );
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const breadcrumbs = [
    <Typography key="1" className="breadTitle" onClick={handleClick}>
      {module}
    </Typography>,
    <Typography key="2" className="breadTitle" onClick={handleClick}>
      {partyCode}
    </Typography>,
    <Typography key="3" className="breadTitle">
      {tabName}
    </Typography>,
    <Typography key="3" className="breadTitle">
      {btnName}
    </Typography>,
  ];

  return (
    <Stack spacing={3} mt={-8} mb={1}>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="small" sx={{ color: "#005724" }} />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
