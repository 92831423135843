import {
  Card,
  InputBase,
  Paper,
  Stack,
  TableContainer,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Pagination,
  MenuItem,
  TextField,
  Button,
  PaginationItem,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import styled from "styled-components/macro";
import { SortLabel } from "components/AllSvg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import "assets/styles/partymasterdatatable.css";
import UnAuthorized from "./UnAuthorized";
import { setSort } from "store/requeststatement/slice";
import IsLoadingHOC from "components/loader/loader";
import makeStyles from "@mui/styles/makeStyles";
import { getAllCompanies } from "store/setting/slice";
import "assets/styles/selectpartytable.css";
import "assets/styles/statementrequest.css";
import "assets/styles/menu.css";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
    &::placeholder {
      color: #000;
      opacity: 1;
    }
  }
`;

const headCells = [
  {
    id: "recoRequestCode",
    disablePadding: false,
    label: "Sr. No.",
  },
  {
    id: "recoType",
    disablePadding: false,
    label: "Company Name",
  },
  {
    id: "recoName",
    disablePadding: false,
    label: "Assigned Rule Group",
  },
];

function EnhancedTableHead(props) {
  const dispatch = useDispatch();
  const { sort } = useSelector((state) => state.trackreconciliation);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={
              sort.id === headCell.id
                ? sort.direction === "ascending"
                  ? "asc"
                  : "desc"
                : "asc"
            }
          >
            <TableSortLabel
              IconComponent={() => (
                <Box sx={{ marginTop: "15px" }}>
                  <SortLabel />
                </Box>
              )}
              active={headCell.id === sort.id}
              direction={
                sort.id === headCell.id
                  ? sort.direction === "ascending"
                    ? "asc"
                    : "desc"
                  : "asc"
              }
              onClick={() =>
                dispatch(
                  setSort({
                    id: headCell.id,
                    direction:
                      sort.direction === "ascending"
                        ? "descending"
                        : "ascending",
                  })
                )
              }
            >
              {headCell.label}
              {sort.id === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sort.direction === "ascending"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const AssignedRule = ({ setLoading }) => {
  const { isAdmin } = useSelector((state) => state.masterData);
  const [rowData, setRowData] = useState([]);
  const [searchString] = useState("");

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAllCompanies()).then((res) => {
      let obj = Array.from(res?.payload?.companyMappings).map((val) => {
        return {
          companyId: val?.companyId,
          groupName: val?.ruleGroupName,
        };
      });
      setRowData(obj);
      setLoading(false);
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, rowsPerPage, searchString]);

  const handleChangePage = (_, newPage) => {
    let p = newPage;
    setPage(p);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
  }));

  const classes = useStyles();

  return !isAdmin ? (
    <UnAuthorized />
  ) : (
    <Card sx={{ borderRadius: "7px", height: "100%", padding: "16px" }}>
      <Stack marginX={"0px"} direction={"column"} marginBottom={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            fontFamily={"fontsfree"}
            fontSize={"20px"}
            marginY={"8px"}
          >
            Assigned Rule Group to Company
          </Typography>

          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              marginTop: "-10px",
            }}
          >
            {/*Search bar*/}
            <div
              className="search-box-div2"
              style={{ backgroundColor: "F3F3F3" }}
            >
              <div className="iconwrapper">
                <SearchIcon />
              </div>
              <Input placeholder={"Search"} />
            </div>
            {/* New Reconciliation Button */}
            <Button
              variant="contained"
              style={{ background: "#005724", marginLeft: "20px" }}
              className="new-butn"
            >
              Assign Rule Group
            </Button>
          </Toolbar>
        </Stack>
      </Stack>
      <Box bgcolor={"#F3F3F3"} padding="16px" borderRadius={"7.5px"}>
        <Paper
          style={{
            border: "0.5px solid #BBBBBB",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size={"small"}
              sx={{ bgcolor: "#FFF", borderRadius: "7.5px" }}
            >
              <EnhancedTableHead rowCount={rowData.length} />
              <TableBody sx={{ maxHeight: "70vh" }}>
                {rowData.map((row, index) => {
                  return (
                    <StyledTableRow key={row.ruleGroupId}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.companyId}</TableCell>
                      <TableCell align="center">{row.groupName}</TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* toolbar for pagination and row per page */}
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pr: { xs: 1, sm: 1 },
          }}
          style={{ marginLeft: "-20px" }}
        >
          <TextField
            select
            size="small"
            className="page-per-row"
            defaultValue={10}
            onChange={handleChangeRowsPerPage}
            sx={{
              ".MuiSelect-select": {
                fontFamily: "SFProdisplay",
                fontStyle: "normal",
                fontWeight: "700",
                color: "#005724",
              },
              ".MuiSvgIcon-root-ffqUiY": {
                color: "#005724",
              },
            }}
          >
            <MenuItem className="menu-items" value={10}>
              10 per page
            </MenuItem>
            <MenuItem value={25}>25 per page</MenuItem>
            <MenuItem value={50}>50 per page</MenuItem>
            <MenuItem value={100}>100 per page</MenuItem>
          </TextField>

          <Stack spacing={2} className="pagination-box">
            <Pagination
              page={page}
              shape="rounded"
              component="div"
              defaultPage={1}
              count={pageCount}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  variant="outlined"
                  {...item}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                />
              )}
            />
          </Stack>
        </Toolbar>
      </Box>
    </Card>
  );
};

export default IsLoadingHOC(AssignedRule);
