import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CloseIcon } from "components/AllSvg";
import { IconButton, Stack, TextareaAutosize } from "@mui/material";
import "assets/styles/reviewdone.css";
import { useDispatch, useSelector } from "react-redux";
import { markAsReviewDone, setRefresh } from "store/requeststatement/slice";

const style = {
  width: "90vw",
  maxWidth: "490px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  padding: "14px",
};

const submit = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

export default function ReviewDone(props) {
  const [comment, setComment] = React.useState("");

  const { selected, recoId, recoStatus, refresh } = useSelector(
    (state) => state.trackreconciliation
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    setComment("");
    props.setOpen(false);
  };
  const handleSubmit = () => {
    dispatch(
      markAsReviewDone({
        recoId,
        partyIds: selected.length === 0 ? [props.partyId] : selected,
        comment,
        isAllSelected: props.isAllSelected,
      })
    ).then(() => {
      props.setRefresh(!props.refresh);
      dispatch(setRefresh(!refresh));
      handleClose();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ dispaly: "inline" }}
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between">
          <Typography className="popuptitle">Review Completed</Typography>
          <IconButton onClick={handleClose} disableRipple>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography className="popupsubtitle">
          Are you sure you want to mark as Review Done?
        </Typography>
        <Box
          width="100%"
          marginY={2}
          bgcolor="#005724"
          padding={2}
          borderRadius={2}
          border="1px solid black"
          boxShadow="rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"
        >
          <Typography color="#FFF" fontFamily="SFProdisplay">
            {` Action will be taken for the parties with status ${recoStatus} only`}
          </Typography>
        </Box>
        <Typography>Comment (if any)</Typography>
        <TextareaAutosize
          minRows={1}
          style={{
            width: "90vw",
            maxWidth: "450px",
            border: "1px solid #BBBBBB",
            borderRadius: "5px",
            padding: "8px",
            fontFamily: "SFProdisplay",
          }}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          id="comment"
          variant="outlined"
          size="small"
          autoComplete="off"
          name="comment"
        />
        <Stack
          spacing={2}
          direction="row"
          marginTop={2}
          justifyContent="flex-end"
        >
          <Button className="cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button style={submit} onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
