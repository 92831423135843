const initialValues = {
  status: null,
  error: null,
  type: null,
  recoId: null,
  partyId: null,
  token: null,
  statementId: null,
  partyStatementData: [],
  validateData: [],
  partyData: [],
  confirmerId: null,
  openItemFile: [],
  openItemFileName: null,
  ledgerFile: [],
  ledgerFileName: null,
  isLedgerFile: false,
  isOpenItemFile: false,
  ledgerOrOpenItemUpload: "",
  show: {
    id: true,
    map: false,
    req: false,
    sign: true,
    verify: false,
    reply: false,
    tabs: false,
    amount: false,
    upload: false,
    attach: true,
    confirm: false,
    modal: false,
    download: false,
    downloadCopy: false,
    ledgerFileSubmit: false,
    openItemFileSubmit: false,
    everythinglooksgood: false,
    mappingData: [],
    summary: null,
    mapOpen: false,
  },
  removedData: null,
  actionrequest: null,
  openItemStatementData: {},
  confirmationType: "",
  currency: "INR",
  infoboxdata: {},
  clAmount: null,
  firstRenderStr: "",
  partyRemark: "",
  checkLedger: false,
  checkOpenItem: false,
  ledgetFileType: "xl",
  openItemFileType: "xl",
  ledgerStatementId: "",
  openItemStatementId: "",
  openUploadModal: false,
  partyConfirmationDetails: {},
};

export default initialValues;
