import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
    {
      components: {
        MuiToggleButton: {
          styleOverrides: {
            root: {
              color: "#000",
              backgroundColor: "#fff",
              padding: "auto",
              height: "35px",
              borderColor: "#bbbbbb",
              borderRadius: "5px",
              fontWeight: "400",
              "&.Mui-selected": {
                color: "#fff",
                fontWeight: "bold",
                backgroundColor: "#005724",
                borderColor: "#005724",
                "&:hover": {
                  backgroundColor: "#005724",
                },
              },
              "&:hover": {
                backgroundColor: "#fff",
              },
            },
          },
        },
      },
    }
  );
};

export default createTheme;
