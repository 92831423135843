import React from "react";
import { Card, CardContent } from "@mui/material";
const ActionDocumentRequested = () => {
  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        ActionDocumentRequested
      </CardContent>
    </Card>
  );
};

export default ActionDocumentRequested;
