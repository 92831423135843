import React from "react";
import ActionRecoSummary from "./ActionRecoSummary";
import ActionDocumentRequested from "./ActionDocumentRequested";

const ChangeTabComponents = ({ step }) => {
  const changeForm = (val) => {
    switch (val) {
      case "1":
        return <ActionRecoSummary />;
      case "2":
        return <ActionDocumentRequested />;
      default:
        break;
    }
  };
  return <div>{changeForm(step)}</div>;
};

export default ChangeTabComponents;
