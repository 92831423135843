const initialValues = {
  error: null,
  type: null,
  report: null,
  reportsummary: null,
  recoId: "",
  activeValue: "1",
  companyData: [],
  statisticsData: {},
  AllPartyData: [],
  ReconciliationData: [],
  ReviewData: [],
  partySignOffData: [],
  actionTrackerData: [],
  contactPersonType: [],
  reminderTemplate: [],
  emailAttachment: [],
  selected: [],
  modifyConfigurationData: [],
  modifyConfigData: {},
  recoName: "",
  partyName: "",
  partyIds: [],
  signOff: null,
  recopendingselected: [],
  partySignOffSelected: [],
  highPriority: [],
  reminderTemplateType: [],
  templateContentType: [],
  gotIt: true,
  totalItems: 0,
  reviewPendingSelected: [],
  recoStatus: "",
  partyContacts: [],
  allComments: [],
  partyInfo: {},
  recoSummary: {},
  UnmatchedData: [],
  partyId: "",
  code: "",
  partySummaryData: {},
  trackRecoPage: 1,
  partyCode: "",
  partyDataIds: [],
  companyDataIds: [],
  queryData: {},
  sumofRecords: "",
  recoPendingCount: 0,
  viewremarksdata: {},
  countOfRecord: "",
  module: "",
  requestType: "",
  recoIdofRow: "",
  sort: { id: "", direction: "descending" },
  refresh: false,
  recoOnOffStatus: "",
  recoFromTrackScreen: false,
  viewActionTrackerData: [],
  actionTrackerTableData: [],
  actionViewTrackerSelected: [],
  actionTakenStatusforViewActionTracker: "",
  actionTrackerPartyId: [],
  actionTrackerPartyCode: "",
  actionTrackerSummaryCode: "",
  tabName: "Statistics",
  btnName: "",
  attchementCommentTableData: [],
  attchementCommentForDrawerComments: [],
  selectedInfo: {},
  reportStatusData: {},
  isAllDataIdsSelected: false,
  partyMatchedDataIds: [],
  companyMatchedDataIds: [],
  MatchedData: {},
  totalAmount: "",
  selectedAmount: [0],
  configData: {},
  uncheckedDataIds: [],
  unselectedAmount: [],
  totalSum: 0,
  summaryReportStatus: "",
  detailedReportStatus: "",
  partyDocList: [],
  isSignoffCompleted: false,
  confirmationDetails: {},
  isMiniSidebar: false,
  reminderList: [],
  showAll: false,
};

export default initialValues;
