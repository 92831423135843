import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const UnAuthorized = () => {
  const navigate = useNavigate();
  const style = {
    color: "white",
    backgroundColor: "red",
    paddingInline: "16px",
    paddingBlock: "8px",
    width: "max-content",
    borderRadius: "7px",
  };

  return (
    <>
      <h1 style={style}>You Are Not Authorised!</h1>
      <br />
      <Button onClick={() => navigate(-1)} variant="outlined">
        <h1 style={style}>Go-Back</h1>
      </Button>
    </>
  );
};

export default UnAuthorized;
