import "assets/styles/statistics.css";
import { Tabs, Tab, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setActiveValue } from "../../../../store/requeststatement/slice";
import ChangeTabComponents from "./ChangeTabComponents";

const RecoAndDocumentMainCard = () => {
  const dispatch = useDispatch();
  const { activeValue } = useSelector((state) => state.trackreconciliation);

  //To change Tab values
  const handleChangeTab = (event, newValue) => {
    dispatch(setActiveValue(newValue));
  };

  return (
    <>
      <Box marginTop="-20px">
        <Tabs
          className="tabs-main"
          value={activeValue}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          textColor="secondary"
          sx={{
            ".MuiButtonBase-root.Mui-selected": {
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "700",
              textAlign: "center",
              color: "#005724",
            },
            ".MuiTabs-indicator": {
              height: "5px",
              width: "4px",
              background: "#FF9A01",
              borderRadius: "5px",
            },
            marginLeft: "500px",
          }}
        >
          <Tab className="tab-menu" label="Reconciliation Summary" value="1" />
          <Tab className="tab-menu" label="Document Requested" value="2" />
        </Tabs>
        <ChangeTabComponents step={activeValue} />
      </Box>
    </>
  );
};
export default RecoAndDocumentMainCard;
