import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosPartyApi from "helper/axiosPartyApi";
import state from "./state";

// get party Reconcilation summary data
export const getPartySummaryData = createAsyncThunk(
  "getPartySummaryData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/reconinit/api/v1/recorecords/tracking/partysummary`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Party Screen Reco Summary Raise Query Action
export const raiseQuery = createAsyncThunk(
  "raiseQuery",
  async ({ token, data, callBack }, { rejectWithValue }) => {
    const URL = callBack
      ? "/reconinit/api/v1/recorecords/tracking/add-callback"
      : "/reconinit/api/v1/recorecords/tracking/add-query";

    try {
      const res = await axiosPartyApi.put(URL, data);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get party Reconcilation summary Query details
export const getQueryDetails = createAsyncThunk(
  "getQueryDetails",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/reconinit/api/v1/recorecords/tracking/party-query`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get All Comments Party
export const getAllCommentsParty = createAsyncThunk(
  "getAllCommentsParty",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/reconinit/api/v1/recorecords/tracking/party/all-comments`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadDocument = createAsyncThunk(
  "downloadDocument",
  async ({ recoId, partyId, doc }, { rejectWithValue }) => {
    try {
      axiosPartyApi
        .get(
          `/reconinit/api/v1/recorecords/tracking/download-document?recoId=${recoId}&partyId=${partyId}&documentName=${doc}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${doc}`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Unmatched Data
export const getUnMatchedData = createAsyncThunk(
  "getUnMatchedData",
  async (
    { code, source1Val, source2Val, page, limit, sortBy, searchString },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosPartyApi.get(
        `/recon/api/v1/party/recorecords/by-code?code=${code}&pageNo=${page}&pageSize=${limit}&source1=${source1Val}&source2=${source2Val}${sortBy
          ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
          : ""
        }&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get status dropdown list
export const getStatusUnmatchedList = createAsyncThunk(
  "getStatusUnmatchedList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/recon/api/v1/recorecords/masterdata/statuslist`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get source 1 dropdown list-company data set
export const getSourceOneList = createAsyncThunk(
  "getSourceOneList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/recon/api/v1/party/recorecords/masterdata/company-dataset`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get source 2 dropdown list-party data set
export const getSourceTwoList = createAsyncThunk(
  "getSourceTwoList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/recon/api/v1/party/recorecords/masterdata/party-dataset`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Party disagree popup
export const partyDisagree = createAsyncThunk(
  "partyDisagree",
  async ({ comment, companyDataIds, partyDataIds }, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.put(
        `/recon/api/v1/party/recorecords/disagree`,
        { comment, companyDataIds, partyDataIds }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Add remark modal-for unmatched data screen
export const addRemark = createAsyncThunk(
  "addRemark",
  async ({ remark, dataIds }, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.put(
        `/recon/api/v1/party/recorecords/add-remark`,
        { remark, dataIds }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Party Send Verification Code
export const sendVerificationCode = createAsyncThunk(
  "sendVerificationCode",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.put(
        "/reconinit/api/v1/recorecords/tracking/signoff/verification-code",
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Party Send Verification Code
export const verifyOTP = createAsyncThunk(
  "verifyOTP",
  async ({ confirmerId, verificationCode }, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.put(
        `/reconinit/api/v1/recorecords/tracking/signoff/verify-code?confirmerId=${confirmerId}&verificationCode=${verificationCode}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Party Add Signoff Comment
export const addSignOffComment = createAsyncThunk(
  "addSignOffComment",
  async ({ comment }, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.put(
        `/reconinit/api/v1/recorecords/tracking/signoff/comment?comment=${comment}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// View Remarks- List of remarks on Add Remark Modal(Unmatched/matched Screen)
export const getViewRemarkData = createAsyncThunk(
  "getViewRemarkData",
  async ({ dataId }, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/recon/api/v1/party/recorecords/get-remarks?dataId=${dataId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Get DisAgree Count
export const getDisagreeCount = createAsyncThunk(
  "getDisagreeCount",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/recon/api/v1/party/recorecords/disagree/count`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Get DisAgree Count
export const getRecoDetails = createAsyncThunk(
  "getRecoDetails",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/reconinit/api/v1/recorecords/tracking/party/recodetail`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Get Summary File Count
export const downloadReport = createAsyncThunk(
  "downloadReport",
  async ({ fileName }, { rejectWithValue }) => {
    try {
      axiosPartyApi
        .get(
          `/reconreport/api/v1/party/recoadvancereport?reportName=${fileName}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Comment
export const addComment = createAsyncThunk(
  "addComment",
  async ({ comment, commentType }, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.put(
        `/reconinit/api/v1/recorecords/tracking/party/add-comment`,
        { comment, commentType }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Reco Repor status Data
export const getReportStatusData = createAsyncThunk(
  "getReportStatusData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.get(
        `/reconreport/api/v1/party/largerecoreport`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Generate Reco Report
export const generateRecoReport = createAsyncThunk(
  "getReportStatusData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosPartyApi.post(
        `/reconreport/api/v1/generate/party/largerecoreport`,
        null
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadGeneratedReport = createAsyncThunk(
  "downloadGeneratedReport",
  async ({ reportName }, { rejectWithValue }) => {
    try {
      axiosPartyApi
        .get(
          `/reconreport/api/v1/download/party/largerecoreport?reportName=${reportName}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", reportName);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const partyRecoSlice = createSlice({
  name: "partyRecoSlice",
  initialState: state,
  extraReducers: {
    //Get Status dropdown list
    [getPartySummaryData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_PARTY_SUMMARY_DATA";
    },
    [getPartySummaryData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_PARTY_SUMMARY_DATA";
      state.partySummaryData = action.payload;
    },
    [getPartySummaryData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_PARTY_SUMMARY_DATA";
      state.error = action.payload;
    },
    //Party Screen Reco Summary Raise Query Action
    [raiseQuery.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RAISE_QUERY";
    },
    [raiseQuery.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RAISE_QUERY";
    },
    [raiseQuery.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RAISE_QUERY";
      state.error = action.payload;
    },
    //Party Screen Reco Summary Query Details
    [getQueryDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "QUERY_DETAILS";
    },
    [getQueryDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "QUERY_DETAILS";
      state.queryData = action.payload;
    },
    [getQueryDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "QUERY_DETAILS";
      state.error = action.payload;
    },
    //Get All Comments Party
    [getAllCommentsParty.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_COMMENTS";
    },
    [getAllCommentsParty.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_COMMENTS";
      state.allComments = action.payload;
    },
    [getAllCommentsParty.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_COMMENTS";
      state.error = action.payload;
    },
    // Download document
    [downloadDocument.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadDocument.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadDocument.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_REPORT";
      state.error = action.payload;
    },
    //Get unmatched Data
    [getUnMatchedData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_MATCHED_DATA";
    },
    [getUnMatchedData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_MATCHED_DATA";
      state.UnmatchedData = action.payload;
    },
    [getUnMatchedData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_MATCHED_DATA";
      state.error = action.payload;
    },
    //Get Status dropdown list
    [getStatusUnmatchedList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATUS_LIST";
    },
    [getStatusUnmatchedList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATUS_LIST";
    },
    [getStatusUnmatchedList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATUS_LIST";
      state.error = action.payload;
    },
    //Get source One dropdown list
    [getSourceOneList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SOURCE1_LIST";
    },
    [getSourceOneList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SOURCE1_LIST";
    },
    [getSourceOneList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SOURCE1_LIST";
      state.error = action.payload;
    },
    //Get source tWO dropdown list
    [getSourceTwoList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SOURCE2_LIST";
    },
    [getSourceTwoList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SOURCE2_LIST";
    },
    [getSourceTwoList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SOURCE2_LIST";
      state.error = action.payload;
    },
    //Party Disagree api
    [partyDisagree.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SOURCE2_LIST";
    },
    [partyDisagree.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SOURCE2_LIST";
    },
    [partyDisagree.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SOURCE2_LIST";
      state.error = action.payload;
    },
    //addRemark
    [addRemark.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ADD_REMARK";
    },
    [addRemark.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ADD_REMARK";
    },
    [addRemark.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ADD_REMARK";
      state.error = action.payload;
    },
    //Party Send Verification Code
    [sendVerificationCode.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RAISE_QUERY";
    },
    [sendVerificationCode.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RAISE_QUERY";
    },
    [sendVerificationCode.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RAISE_QUERY";
      state.error = action.payload;
    },
    //Party Verify OTP
    [verifyOTP.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RAISE_QUERY";
    },
    [verifyOTP.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RAISE_QUERY";
    },
    [verifyOTP.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RAISE_QUERY";
      state.error = action.payload;
    },
    //Party Add SignOff Comment OTP
    [addSignOffComment.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RAISE_QUERY";
    },
    [addSignOffComment.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RAISE_QUERY";
    },
    [addSignOffComment.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RAISE_QUERY";
      state.error = action.payload;
    },
    //View Remark List
    [getViewRemarkData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VIEW_REMARK_LIST";
    },
    [getViewRemarkData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VIEW_REMARK_LIST";
      state.viewremarksdata = action.payload;
    },
    [getViewRemarkData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VIEW_REMARK_LIST";
      state.error = action.payload;
    },
    //Get DisAgree Count
    [getDisagreeCount.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VIEW_REMARK_LIST";
    },
    [getDisagreeCount.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VIEW_REMARK_LIST";
      state.disagreeCount = action.payload.disagreeCount;
    },
    [getDisagreeCount.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VIEW_REMARK_LIST";
      state.error = action.payload;
    },
    //Get Reco Details
    [getRecoDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VIEW_REMARK_LIST";
    },
    [getRecoDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VIEW_REMARK_LIST";
      state.recoDetails = action.payload;
    },
    [getRecoDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VIEW_REMARK_LIST";
      state.error = action.payload;
    },
    [downloadReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_REPORT";
      state.report = action.payload;
    },
    [downloadReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_REPORT";
      state.error = action.payload;
    },
    //Add comment
    [addComment.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ADD_COMMENT";
    },
    [addComment.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ADD_COMMENT";
    },
    [addComment.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ADD_COMMENT";
      state.error = action.payload;
    },
    //GET REPORT STATUS comment
    [getReportStatusData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REPORT_STATUS";
    },
    [getReportStatusData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REPORT_STATUS";
    },
    [getReportStatusData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REPORT_STATUS";
      state.error = action.payload;
    },
    //Generate reco report
    [generateRecoReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GENERATE_RECO_REPORT";
    },
    [generateRecoReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GENERATE_RECO_REPORT";
    },
    [generateRecoReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GENERATE_RECO_REPORT";
      state.error = action.payload;
    },
    //Download Generated reco report
    [downloadGeneratedReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_GENERATED_REPORT";
    },
    [downloadGeneratedReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_GENERATED_REPORT";
    },
    [downloadGeneratedReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_GENERATED_REPORT";
      state.error = action.payload;
    },
  },
  reducers: {
    setRecoId(state, action) {
      state.recoId = action.payload;
    },
    setPartyId(state, action) {
      state.partyId = action.payload;
    },
    setCode(state, action) {
      state.code = action.payload;
    },
    setPartyIds(state, action) {
      state.partyIds = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setPartyDataIds(state, action) {
      //for matched/unmatched Data screens
      state.partyDataIds = action.payload;
    },
    setCompanyDataIds(state, action) {
      //for matched/unmatched Data screens
      state.companyDataIds = action.payload;
    },
    setSort(state, action) {
      state.sort = action.payload;
    },
    setSumOfRecords(state, action) {
      state.sumofRecords = action.payload;
    },
    setSummaryPdf(state, action) {
      state.summaryPdf = action.payload;
    },
    setPartyName(state, action) {
      state.partyName = action.payload;
    },
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
    setDataIdsForActionRemark(state, action) {
      state.dataIdsForAddRemark = action.payload;
    },
  },
});

export const {
  setRecoId,
  setPartyId,
  setCode,
  setSelected,
  setPartyDataIds,
  setCompanyDataIds,
  setSort,
  setPartyIds,
  setSumOfRecords,
  setSummaryPdf,
  setPartyName,
  setCompanyName,
  setDataIdsForActionRemark,
} = partyRecoSlice.actions;
const { reducer } = partyRecoSlice;

export default reducer;
