import { Menu, MenuItem, Typography } from "@mui/material";
import { CloseIcon, DeleteIconSmall, MoreActions } from "components/AllSvg";
import { RetransformIcon } from "components/AllSvg2";
import React, { memo, useState } from "react";
const SftpMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <MoreActions
        className="bar-icon"
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleMenuClick}
      />
      <Menu
        id="menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 15px",
          },
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        sx={{ marginTop: "4px", marginLeft: "-85px" }}
      >
        <MenuItem sx={{ minWidth: "175px" }}>
          <Typography
            fontFamily="fontsfree"
            marginRight="auto"
            color={"#005724"}
          >
            Actions{" "}
          </Typography>
          <CloseIcon onClick={handleClose} />
        </MenuItem>
        <MenuItem onClick={() => {}}>
          <RetransformIcon />
          <Typography fontFamily="ekmukta-bold" marginLeft={2}>
            Retransform
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => {}}>
          <DeleteIconSmall />
          <Typography fontFamily="ekmukta-bold" marginLeft={2}>
            Delete
          </Typography>
        </MenuItem>
        {/* <MenuItem onClick={() => {}}>
          <NotInterestedIcon fontSize="16px" />
          <Typography fontFamily="ekmukta-bold" marginLeft={2}>
            Stop
          </Typography>
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default memo(SftpMenu);
