import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  Button,
  Modal,
  Grid,
  IconButton,
  Stack,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { CloseIcon, FileAttachment, RedCloseIcon } from "components/AllSvg";
import "assets/styles/reviewdone.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getActionTrackerActionTakenStatusFilterList,
  updateActionTakenStatusofActionTracker,
  setActionViewTrackerSelected,
  setUnselected,
  setSelected,
} from "store/requeststatement/slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import makeStyles from "@mui/styles/makeStyles";
import DummyButton from "components/button/DummyButton";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#f6f6f6",
  maxWidth: "40%",
  scale: "90%",
};

const submit = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

const useStyles = makeStyles(() => ({
  selected: {
    color: "#FFFFFF",
    background: "#005724",
  },
  root: {
    color: "#005724",
    background: "#FFFFFF",
    border: "1px solid #005724",
    borderRadius: "4px",
  },
  cookieAlert: {
    "& .MuiAlert-icon": {
      color: "#ffffff",
    },
  },
}));

const ActionTrackerUpdateActionTakenModal = (props) => {
  const [actionTakenStatusList, setactionTakenStatusList] = useState([]);
  const [firstFile, setFirstFile] = useState([]);
  const [secondFile, setSecondFile] = useState([]);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [backenderrormsg, setBackendErrormsg] = useState(false);
  const [backendsuccessmsg, setBackendSuccessmsg] = useState(false);
  const [showbackenderrormsg, setShowBackendErrormsg] = useState("");
  const [showbackendSuccessmsg, setShowBackendSuccessmsg] = useState("");
  const [disabled, setDisabled] = useState(false);

  const {
    viewActionTrackerData,
    actionViewTrackerSelected,
    uncheckedDataIds,
    recoId,
  } = useSelector((state) => state.trackreconciliation);
  const dispatch = useDispatch();
  const classes = useStyles();

  const form = {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "15px",
    boxShadow: "0px 4px 15px 0px #00000040",
  };

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      actionStatus: "",
      actionReference: "",
      remark: "",
    },
    validationSchema: Yup.object().shape({
      actionStatus: Yup.string().required("Select Action Taken Status"),
    }),

    onSubmit: (val) => {
      setDisabled(true);
      let result = [];
      viewActionTrackerData?.actionTrackerRecords?.forEach((option1, index) => {
        actionViewTrackerSelected?.forEach((option2, index) => {
          if (option1.dataId === option2) {
            var obj = {
              recoId: option1.recoId,
              partyId: option1.partyId,
              dataId: option1.dataId,
            };
            result.push(obj);
          }
        });
      });

      const formData = new FormData();
      [firstFile[0], secondFile[0]].forEach((file) => {
        formData.append("documents", file);
      });

      formData.append(
        "actionrequest",
        JSON.stringify({
          ...val,
          dataIds: result,
          uncheckedDataIds,
          recoId,
          selectAll: props.selectAll,
        })
      );

      dispatch(updateActionTakenStatusofActionTracker({ data: formData })).then(
        (res) => {
          if (res?.payload?.success === false) {
            setBackendErrormsg(true);
            setShowBackendErrormsg(res?.payload?.message);
          } else {
            setDisabled(false);
            setBackendSuccessmsg(true);
            setShowBackendSuccessmsg(res?.payload?.message);
          }
          props.setRefresh(!props.refresh);
          handleClose();
          dispatch(setActionViewTrackerSelected([]));
          dispatch(setUnselected([]));
          dispatch(setSelected([]));
          props.setIsAllSelected(false);
          props.setIsItemSelected(false);
        }
      );
    },
  });

  const { getInputProps, open } = useDropzone({
    noKeyboard: true,
    multiple: false,
    minSize: 0,
    maxSize: 5242880,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/doc": [],
      "application/pdf": [],
      "application/docx": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: useCallback(
      (acceptedFiles) => {
        setIsFileTooLarge(false);
        if (firstFile.length === 0) {
          setFirstFile(acceptedFiles);
          return;
        }
        if (secondFile.length === 0) {
          setSecondFile(acceptedFiles);
          return;
        }
      },
      [firstFile, secondFile]
    ),
    onDropRejected: useCallback((fileRejections) => {
      setIsFileTooLarge(true);
    }, []),
  });

  const { errors, touched, handleSubmit, setFieldValue } = formik;

  const handleClose = () => {
    props.setOpenUpdateActionTaken(false);
    setFirstFile([]);
    setSecondFile([]);
  };

  //For Action Taken dropdown list
  useEffect(() => {
    dispatch(getActionTrackerActionTakenStatusFilterList()).then((res) => {
      setactionTakenStatusList(res?.payload?.data);
    });
    return () => {
      setactionTakenStatusList([]);
    };
  }, [dispatch]);

  return (
    <>
      <Modal
        open={props.openUpdateActionTaken}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Box sx={form}>
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <Typography
                  fontFamily="fontsfree"
                  fontSize="16px"
                  fontWeight={800}
                  color="#005724"
                >
                  Update Action Taken
                </Typography>
                <IconButton onClick={handleClose} disableRipple>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Grid container spacing={3} marginBottom={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Typography sx={{ marginBottom: "5px", mr: "2px" }}>
                      Action Taken Status
                    </Typography>
                    <span className="text-danger">*</span>
                  </Box>
                  <Select
                    displayEmpty
                    id="demo-simple-select"
                    name="actionStatus"
                    defaultValue=""
                    onChange={(event) => {
                      setFieldValue("actionStatus", event.target.value);
                    }}
                    fullWidth
                    size="small"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {actionTakenStatusList.map((option, index) => (
                      <MenuItem key={index} value={option.actionStatusCode}>
                        {option.actionStatusValue}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.actionStatus && touched.actionStatus ? (
                    <span className="text-danger">{errors.actionStatus}</span>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography sx={{ marginBottom: "5px" }}>
                    Action Taken Reference
                  </Typography>
                  <TextField
                    id="actionReference"
                    name="actionReference"
                    onChange={(e) => {
                      setFieldValue("actionReference", e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    fullWidth
                    multiline
                    rows={1}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Box
                      p={2}
                      mt={2}
                      sx={{
                        border: "1px solid #BBBBBB",
                        height: "40px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input {...getInputProps()} />
                      <FileAttachment></FileAttachment>
                      <Typography
                        ml={4}
                        fontWeight={700}
                        fontFamily="fontsfree"
                      >
                        {firstFile.length === 0 ? "" : firstFile[0]?.name}
                      </Typography>
                      {firstFile.length === 0 ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={open}
                          style={{
                            marginLeft: "auto",
                            backgroundColor: "#ececec",
                            color: "#005724",
                            fontWeight: 700,
                            fontFamily: "fontsfree",
                          }}
                        >
                          Browse&nbsp;File
                        </Button>
                      ) : (
                        <Box style={{ marginLeft: "auto" }}>
                          <RedCloseIcon
                            width="15"
                            height="35"
                            cursor="pointer"
                            onClick={() => setFirstFile([])}
                          />
                        </Box>
                      )}
                    </Box>
                    <Stack direction="row" mt="5px">
                      <Typography
                        mr="auto"
                        fontFamily="SFProdisplay"
                        color="#a7a7a7"
                        fontSize="10px"
                        fontWeight={400}
                      >
                        Acceptable file types : pdf, xls, xlsx, xlsm, csv, doc,
                        docx, jpg, jpeg, png
                      </Typography>
                      <Typography
                        fontFamily="SFProdisplay"
                        color="#a7a7a7"
                        fontSize="10px"
                        fontWeight={400}
                      >
                        File size : 5 MB
                      </Typography>
                    </Stack>

                    {isFileTooLarge && (
                      <div style={{ color: "red" }}>File is too large.</div>
                    )}

                    {secondFile.length === 1 && (
                      <Box
                        p={2}
                        sx={{
                          border: "1px solid #BBBBBB",
                          height: "40px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                        // {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <FileAttachment></FileAttachment>

                        <Typography
                          ml={4}
                          fontWeight={700}
                          fontFamily="fontsfree"
                        >
                          {secondFile.length === 0 ? "" : secondFile[0]?.name}
                        </Typography>
                        <Box style={{ marginLeft: "auto" }}>
                          <RedCloseIcon
                            width="15"
                            height="35"
                            cursor="pointer"
                            onClick={() => setSecondFile([])}
                          />
                        </Box>
                      </Box>
                    )}

                    {firstFile.length === 0 || secondFile.length === 1 ? (
                      <Box marginY="30px"></Box>
                    ) : (
                      <Typography
                        onClick={open}
                        marginY="8px"
                        ml="auto"
                        color="#005724"
                        fontWeight={700}
                        fontSize="12px"
                        fontFamily="fontsfree"
                        sx={{
                          textDecoration: "underline",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        + Add more
                      </Typography>
                    )}

                    <Typography sx={{ marginBottom: "10px" }}>
                      Remarks
                    </Typography>
                    <TextField
                      id="remark"
                      name="remark"
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        setFieldValue("remark", e.target.value);
                      }}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      multiline
                      rows={1}
                    />
                    <Stack
                      spacing={2}
                      direction="row"
                      marginTop={2}
                      justifyContent="flex-end"
                    >
                      <Button className="cancel" onClick={handleClose}>
                        Cancel
                      </Button>
                      {disabled ? (
                        <DummyButton />
                      ) : (
                        <Button style={submit} type="submit">
                          Update
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* for Success */}
      <Snackbar
        open={backendsuccessmsg}
        autoHideDuration={2000}
        onClose={() => {
          setBackendSuccessmsg(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setBackendSuccessmsg(false);
          }}
          severity="success"
          className={classes.cookieAlert}
          sx={{
            width: "100%",
            bgcolor: "rgba(37, 172, 75, 1)",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          {showbackendSuccessmsg}
        </Alert>
      </Snackbar>
      {/* for error message */}
      <Snackbar
        open={backenderrormsg}
        autoHideDuration={2000}
        onClose={() => {
          setBackendErrormsg(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setBackendErrormsg(false);
          }}
          severity="error"
          className={classes.cookieAlert}
          sx={{
            width: "100%",
            bgcolor: "#FF0000",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          {showbackenderrormsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ActionTrackerUpdateActionTakenModal;
