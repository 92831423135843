import React, { useState, useEffect } from "react";
import {
  Select,
  Modal,
  Box,
  Typography,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { CloseIcon } from "components/AllSvg";
import "assets/styles/allpopups.css";
import { useSelector, useDispatch } from "react-redux";
import {
  updateActionOwnerForActionTracker,
  setPartyDataIds,
  setCompanyDataIds,
  setSelected,
  getContactPersonType,
  setActionViewTrackerSelected,
} from "store/requeststatement/slice";
import makeStyles from "@mui/styles/makeStyles";
import DummyButton from "components/button/DummyButton";

const Boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  padding: "18px",
  scale: "90vw",
};

const submit = {
  width: "130px",
  height: "35px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

const cancelStyle = {
  width: "130px",
  height: "35px",
  fontSize: "12px",
  fontWeight: "700",
  background: "#FFFFFF",
  color: "#005724",
  border: "1px solid #FF9A01",
  boxSizing: "border-box",
  borderRadius: "5px",
};

const ActionTrackerActionOwnerModal = (props) => {
  const dispatch = useDispatch();
  const {
    actionViewTrackerSelected,
    viewActionTrackerData,
    uncheckedDataIds,
    recoId,
  } = useSelector((state) => state.trackreconciliation);

  const [comment, setComment] = useState("");
  const [status, setStatus] = useState([]);
  const [statusVal, setStatusVal] = useState(""); // value/Id of selected status dropdown
  const [actionownername, setactionownername] = useState(""); // name of selected status dropdown
  const [actionOwnerErr, setActionOwnerErr] = useState(false); //action owner dropdown list error message
  const [backenderrormsg, setBackendErrormsg] = useState(false);
  const [backendsuccessmsg, setBackendSuccessmsg] = useState(false);
  const [showbackenderrormsg, setShowBackendErrormsg] = useState("");
  const [actionOwnerEmail, setactionOwnerEmail] = useState("");
  const [disabled, setDisabled] = useState(false);

  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
    cookieAlert: {
      "& .MuiAlert-icon": {
        color: "#ffffff",
      },
    },
  }));

  const classes = useStyles();

  //For Action Owner dropdown list
  useEffect(() => {
    dispatch(getContactPersonType()).then((res) => {
      setStatus(res?.payload);
    });
    return () => {
      setStatus([]);
    };
  }, [dispatch]);

  //submit click
  const handleUpdate = () => {
    let result = [];
    viewActionTrackerData?.actionTrackerRecords?.forEach((option1, index) => {
      actionViewTrackerSelected?.forEach((option2, index) => {
        if (option1.dataId === option2) {
          var obj = {
            recoId: option1.recoId,
            partyId: option1.partyId,
            dataId: option1.dataId,
          };
          result.push(obj);
        }
      });
    });

    if (statusVal === "") {
      setActionOwnerErr(true);
    } else {
      setDisabled(true);
      dispatch(
        updateActionOwnerForActionTracker({
          comment: comment,
          actionOwnerName: actionownername,
          actionOwnerEmail: actionOwnerEmail,
          actionOwnerId: statusVal,
          dataIds: result,
          selectAll: props.selectAll,
          recoId: recoId,
          uncheckedDataIds,
        })
      ).then((res) => {
        if (res?.payload?.success === false) {
          setBackendErrormsg(true);
          setShowBackendErrormsg(res?.payload?.message);
        } else {
          setDisabled(false);
          props.setIsItemSelected(false);
          setComment("");
          setBackendSuccessmsg(true);
          setShowBackendErrormsg(res?.payload?.message);
        }
        props.setRefresh(!props.refresh);
        dispatch(setPartyDataIds([]));
        dispatch(setCompanyDataIds([]));
        dispatch(setSelected([]));
        props.setactionTrackerActionOwner(false);
        dispatch(setActionViewTrackerSelected([]));
        props.setIsAllSelected(false);
      });
    }
  };

  //cancel click
  const handleClose = () => {
    props.setactionTrackerActionOwner(false);
  };

  //handle action owner select click
  const handleStatusChange = (event) => {
    setStatusVal(event.target.value);
    setactionownername(
      status.filter((val) => val.id === event.target.value)[0].name
    );
    setactionOwnerEmail(
      status.filter((val) => val.id === event.target.value)[0].email
    );
    setActionOwnerErr(false);
  };

  //handle comment box click
  const handleComment = (event) => {
    setComment(event.target.value);
  };

  return (
    <>
      <Modal
        open={props.actionTrackerActionOwner}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Boxstyle}>
          <Stack direction="row" justifyContent="space-between">
            <Typography className="maintxt">Change Action Owner</Typography>
            <IconButton
              onClick={handleClose}
              disableRipple
              sx={{ marginTop: "-4px" }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography className="mainsubtxt">
            Action Owner <span className="text-danger">*</span>
          </Typography>
          <Select
            displayEmpty
            id="status"
            name="status"
            defaultValue=""
            onChange={handleStatusChange}
            fullWidth
            size="small"
          >
            <MenuItem value="">Select</MenuItem>
            {status.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <br />
          <span className="text-danger">
            {actionOwnerErr ? <> Select Action Owner </> : null}
          </span>

          <Typography className="mainsubtxt">Comment</Typography>
          <TextareaAutosize
            minRows={3}
            style={{
              width: "90vw",
              maxWidth: "450px",
              border: "1px solid #BBBBBB",
              borderRadius: "5px",
              padding: "8px",
              fontFamily: "SFProdisplay",
            }}
            value={comment}
            onChange={handleComment}
            id="comment"
            variant="outlined"
            size="small"
            autoComplete="off"
            name="comment"
          />

          <Stack
            spacing={2}
            direction="row"
            marginTop={2}
            justifyContent="flex-end"
          >
            <Button style={cancelStyle} onClick={handleClose}>
              Cancel
            </Button>
            {disabled ? (
              <DummyButton />
            ) : (
              <Button style={submit} onClick={handleUpdate}>
                Update
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
      {/* for Success */}
      <Snackbar
        open={backendsuccessmsg}
        autoHideDuration={2000}
        onClose={() => {
          setBackendSuccessmsg(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setBackendSuccessmsg(false);
          }}
          severity="success"
          className={classes.cookieAlert}
          sx={{
            width: "100%",
            bgcolor: "rgba(37, 172, 75, 1)",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          {showbackenderrormsg}
        </Alert>
      </Snackbar>

      {/* for error message */}
      <Snackbar
        open={backenderrormsg}
        autoHideDuration={2000}
        onClose={() => {
          setBackendErrormsg(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setBackendErrormsg(false);
          }}
          severity="error"
          className={classes.cookieAlert}
          sx={{
            width: "100%",
            bgcolor: "#FF0000",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          {showbackenderrormsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ActionTrackerActionOwnerModal;
