import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "react-app-polyfill/stable";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import { Provider } from "react-redux";
import { store } from "store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { AxiosInterceptor } from "helper/axiosApi";
import ErrorBoundary from "components/ErrorBoundary";

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider>
        <PersistGate persistor={persistor}>
          <ErrorBoundary>
            <AxiosInterceptor>
              <App />
            </AxiosInterceptor>
          </ErrorBoundary>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);
