import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../helper/axiosApi";
import state from "./state";

// Add all tab data-partymaster data
export const addPartyDetails = createAsyncThunk(
  "addPartyDetails",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(`/partymaster/api/v1/party`, data);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// edit existing party data with partyId
export const editPartyDetails = createAsyncThunk(
  "editPartyDetails",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(`/partymaster/api/v1/party/${id}`, data);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// to get redirect for editing existing party data with partyId
export const getSingleParty = createAsyncThunk(
  "getSingleParty",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/partymaster/api/v1/party/${id}`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to search any value
export const getSearchData = createAsyncThunk(
  "getSearchData",
  async ({ searchString }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(`/partymaster/api/v1/party/search`, {
        searchString,
      });
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get partymaster data table
export const getTableData = createAsyncThunk(
  "getTableData",
  async ({ pageNo, pageSize, sortBy, searchString }, { rejectWithValue }) => {
    try {
      if (searchString === undefined) {
        searchString = "";
      }
      const res = await axiosApi.get(
        // /partymaster/api/v1/party/by-company?pageNo=0&pageSize=10&sortBy=partyCode&sortDirection=descending&searchString=customer
        `/partymaster/api/v1/party/by-company?pageNo=${pageNo}&pageSize=${pageSize}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&searchString=${searchString}`
      );

      // const res = await axiosApi.get(
      //   `/partymaster/api/v1/party/by-company?pageNo=${pageNo}&pageSize=${pageSize}${
      //     sortBy
      //       ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
      //       : ""
      //   }&searchString=${searchText}`
      // );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get party type dropdown
export const getPartyType = createAsyncThunk(
  "getPartyType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/masterdata/api/v1/partytypes`);
      return res.data.partytypes;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get status type dropdown
export const getStatusType = createAsyncThunk(
  "getStatusType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/masterdata/api/v1/statustypes`);
      return res.data.statuslist;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get frequency type dropdown
export const getFrequencyType = createAsyncThunk(
  "getFrequencyType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/masterdata/api/v1/frequencies`);
      return res.data.frequencies;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get category type dropdown
export const getCategoryType = createAsyncThunk(
  "getCategoryType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/masterdata/api/v1/categories`);
      return res.data.categories;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get MSME type dropdown
export const getMSMEType = createAsyncThunk(
  "getMSMEType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/masterdata/api/v1/msmetypes`);
      return res.data.msmeclasses;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get MSME Class type dropdown
export const getMSMEClass = createAsyncThunk(
  "getMSMEClass",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/masterdata/api/v1/msmeclasses`);
      return res.data.msmeclasses;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get Company type dropdown
export const getCompanyType = createAsyncThunk(
  "getCompanyType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/company/api/v1/companies`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get Internal Team type dropdown
export const getInternalTeamType = createAsyncThunk(
  "getInternalTeamType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/company/api/v1/companyauditees`);
      return res.data.auditeeds;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to Upload Party file-Import File
export const uploadParty = createAsyncThunk(
  "uploadParty",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/partymaster/api/v1/party/import/new`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to download error excel file-Import data table
export const downloadErrorFile = createAsyncThunk(
  "downloadErrorFile",
  async (_, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          filename: "200-PartyMasterUpload-Test.xlsx",
        },
        responseType: "arraybuffer",
      };
      const res = await axiosApi.get(
        `/partymaster/api/v1/party/importsummary/download`,
        config
        // `/partymaster/api/v1/party/importsummary/download?filename`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get data for import data table-party master
export const getImportDataTableRecords = createAsyncThunk(
  "getImportDataTableRecords",
  async ({ fileName, page, limit, sortBy }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/partymaster/api/v1/party/importdata/error-records?filename=${fileName}&pageNo=${page}&pageSize=${limit}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to export party details
export const exportPartyDetails = createAsyncThunk(
  "exportPartyDetails",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/partymaster/api/v1/party/export`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to download template
export const downloadTemplate = createAsyncThunk(
  "downloadTemplate",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/partymaster/api/v1/party/template`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getToken = createAsyncThunk(
  "getToken",
  async (data, { rejectWithValue }) => {
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const res = await axios.post(`${baseURL}/token/api/v1/tokens`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get User Details
export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/company/api/v1/user-detail`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Report Count
export const getReportCount = createAsyncThunk(
  "getReportCount",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconreport/api/v1/all-report/status`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadXLReport = createAsyncThunk(
  "downloadXLReport",
  async ({ reportId, reportName }, { rejectWithValue }) => {
    try {
      axiosApi
        .get(
          `/reconreport/api/v1/report/download?reportId=${reportId}&reportName=${reportName}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", reportName);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get User Details
export const stopGeneratingReport = createAsyncThunk(
  "stopGeneratingReport",
  async ({ reportId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconreport/api/v1/report/stop?reportId=${reportId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get status actions
export const getStatusActions = createAsyncThunk(
  "getStatusActions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/statusactions?action=reject_statement`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const masterDataSlice = createSlice({
  name: "masterDataSlice",
  initialState: state,
  extraReducers: {
    [addPartyDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ADD_PART_DETAILS";
    },
    [addPartyDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ADD_PART_DETAILS";
      state.allParty = action.payload;
    },
    [addPartyDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ADD_PART_DETAILS";
      state.error = action.payload;
    },
    [editPartyDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "EDIT_PARTY_DETAILS";
    },
    [editPartyDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "EDIT_PARTY_DETAILS";
      state.editAllparty = action.payload;
    },
    [editPartyDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "EDIT_PARTY_DETAILS";
      state.error = action.payload;
    },
    [getSingleParty.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SINGLE_PARTY";
    },
    [getSingleParty.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SINGLE_PARTY";
      state.singleParty = action.payload;
      state.partyDetails = action.payload;
    },
    [getSingleParty.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SINGLE_PARTY";
      state.error = action.payload;
    },
    [getTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_TABLE_DATA";
    },
    [getTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_TABLE_DATA";
      state.tableData = action.payload;
    },
    [getTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_TABLE_DATA";
      state.error = action.payload;
    },
    [getPartyType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_PARTY_TYPE";
    },
    [getPartyType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_PARTY_TYPE";
      state.partyType = action.payload;
    },
    [getPartyType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_PARTY_TYPE";
      state.error = action.payload;
    },
    [getStatusType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATUS_TYPE";
    },
    [getStatusType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATUS_TYPE";
      state.statusType = action.payload;
    },
    [getStatusType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATUS_TYPE";
      state.error = action.payload;
    },
    [getFrequencyType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_FREQUENCY_TYPE";
    },
    [getFrequencyType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_FREQUENCY_TYPE";
      state.frequencyType = action.payload;
    },
    [getFrequencyType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_FREQUENCY_TYPE";
      state.error = action.payload;
    },
    [getCategoryType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_CATEGORY_TYPE";
    },
    [getCategoryType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_CATEGORY_TYPE";
      state.categoryType = action.payload;
    },
    [getCategoryType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_CATEGORY_TYPE";
      state.error = action.payload;
    },
    [getMSMEType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_MSME_TYPE";
    },
    [getMSMEType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_MSME_TYPE";
      state.msmeType = action.payload;
    },
    [getMSMEType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_MSME_TYPE";
      state.error = action.payload;
    },
    [getMSMEClass.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_MSME_CLASS";
    },
    [getMSMEClass.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_MSME_CLASS";
      state.msmeClass = action.payload;
    },
    [getMSMEClass.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_MSME_CLASS";
      state.error = action.payload;
    },
    [getCompanyType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_COMPANY_TYPE";
    },
    [getCompanyType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_COMPANY_TYPE";
      state.companyType = action.payload;
    },
    [getCompanyType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_COMPANY_TYPE";
      state.error = action.payload;
    },
    [getInternalTeamType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_INTERNAL_TEAM_TYPE";
    },
    [getInternalTeamType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_INTERNAL_TEAM_TYPE";
      state.internalTeamType = action.payload;
    },
    [getInternalTeamType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_INTERNAL_TEAM_TYPE";
      state.error = action.payload;
    },
    [downloadErrorFile.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_ERROR_FILE";
    },
    [downloadErrorFile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_ERROR_FILE";
      state.errorFile = action.payload;
    },
    [downloadErrorFile.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_ERROR_FILE";
      state.error = action.payload;
    },
    [getImportDataTableRecords.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_DIRECTRECO";
    },
    [getImportDataTableRecords.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_DIRECTRECO";
      state.uploadParty = action.payload;
    },
    [getImportDataTableRecords.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_DIRECTRECO";
      state.error = action.payload;
    },
    [uploadParty.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_PARTY";
    },
    [uploadParty.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_PARTY";
      // state.uploadParty = action.payload;
    },
    [uploadParty.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_PARTY";
      state.error = action.payload;
    },
    [exportPartyDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "EXPORT_PARTY_DETAILS";
    },
    [exportPartyDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "EXPORT_PARTY_DETAILS";
      state.exportParty = action.payload;
    },
    [exportPartyDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "EXPORT_PARTY_DETAILS";
      state.error = action.payload;
    },
    [downloadTemplate.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_TEMPLATE";
    },
    [downloadTemplate.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_TEMPLATE";
      state.template = action.payload;
    },
    [downloadTemplate.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_TEMPLATE";
      state.error = action.payload;
    },
    [getSearchData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEARCH_DATA";
    },
    [getSearchData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEARCH_DATA";
      state.tableData = action.payload;
    },
    [getSearchData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEARCH_DATA";
      state.error = action.payload;
    },
    [getToken.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SINGLE_PARTY";
    },
    [getToken.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SINGLE_PARTY";
    },
    [getToken.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SINGLE_PARTY";
      state.error = action.payload;
    },
    //Get User details
    [getUserDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_USER_DETAILS";
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_USER_DETAILS";
      state.userData = action.payload;
    },
    [getUserDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_USER_DETAILS";
      state.error = action.payload;
    },
    //Get User details
    [getReportCount.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REPORT_COUNT";
    },
    [getReportCount.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REPORT_COUNT";
      state.reportCount = action.payload?.reportCount;
      state.reportList = action.payload?.reportList ?? [];
    },
    [getReportCount.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REPORT_COUNT";
      state.error = action.payload;
    },
    //download Report
    [downloadXLReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_XL_REPORT";
    },
    [downloadXLReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_XL_REPORT";
    },
    [downloadXLReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_XL_REPORT";
      state.error = action.payload;
    },
    //Stop Generating Report Report
    [stopGeneratingReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "STOP_GENERATING_REPORT";
    },
    [stopGeneratingReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "STOP_GENERATING_REPORT";
    },
    [stopGeneratingReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "STOP_GENERATING_REPORT";
      state.error = action.payload;
    },
    //Stop Generating Report Report
    [getStatusActions.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATUS_ACTIONS";
    },
    [getStatusActions.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATUS_ACTIONS";
      state.allowedStatusesForRejectStatement = action.payload
        .replaceAll(/\s/g, "")
        .split(",");
    },
    [getStatusActions.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATUS_ACTIONS";
      state.error = action.payload;
    },
  },
  reducers: {
    setActiveValue(state, action) {
      state.activeValue = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPartyDetails(state, action) {
      state.partyDetails = action.payload;
    },
    setSort(state, action) {
      state.sort = action.payload;
    },
    setToken(state, action) {
      state.outhToken = action.payload;
    },
    setAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setPanError(state, action) {
      state.panError = action.payload;
    },
    setGSTError(state, action) {
      state.gstError = action.payload;
    },
    setUdhyamError(state, action) {
      state.udhyamError = action.payload;
    },
    setUploadedFileName(state, action) {
      state.uploadedFileName = action.payload;
    },
    setIsTokenExpire(state, action) {
      state.isTokenExpire = action.payload;
    },
    setPartyIdForEdit(state, action) {
      state.id = action.payload;
    },
    setSingleParty(state, action) {
      state.singleParty = action.payload;
    },
    setOpenSnackBarImportValidFile(state, action) {
      state.openSnackBarImportValidFile = action.payload;
    },
    setOpenSnackBarImportInValidFile(state, action) {
      state.openSnackBarImportInValidFile = action.payload;
    },
    setDownloadReport(state, action) {
      state.downloadReport = action.payload;
    },
    setShowDownloadBox(state, action) {
      state.showDownloadBox = action.payload;
    },
    setErrorSnackbar(state, action) {
      state.errorSnackbar = action.payload;
    },
    setSnackBarMessage(state, action) {
      state.snackbarMessage = action.payload;
    },
    setComapnyId(state, action) {
      state.companyId = action.payload;
    },
    setIsNewParty(state, action) {
      state.isNewParty = action.payload;
    },
    setIsModified(state, action) {
      state.isChanged = action.payload;
    },
  },
});

export const {
  setLoading,
  setPartyDetails,
  setActiveValue,
  setSort,
  setToken,
  setPanError,
  setUploadedFileName,
  setGSTError,
  setUdhyamError,
  setAdmin,
  setIsTokenExpire,
  setPartyIdForEdit,
  setSingleParty,
  setOpenSnackBarImportValidFile,
  setOpenSnackBarImportInValidFile,
  setDownloadReport,
  setShowDownloadBox,
  setErrorSnackbar,
  setSnackBarMessage,
  setComapnyId,
  setIsNewParty,
  setIsModified,
} = masterDataSlice.actions;

const { reducer } = masterDataSlice;

export default reducer;
