import {
  Badge,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  ArrowLeft,
  CollapseIcon,
  PlusIcon,
  MinusIcon,
  PreparedByIcon,
  ReviewedByIcon,
  SignOffByIcon,
  ReviewDoneIcon,
  DownloadSummary,
} from "components/AllSvg";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  statusActions,
  setCode,
  getQueryDetails,
  getRecoSummary,
  getAllComments,
  setPartyId,
} from "store/requeststatement/slice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect } from "react";
import "assets/styles/recosummary.css";
import SendForReview from "../../components/SendForReview";
import CommentDrawer from "../../components/ActionTrackerCommentDrawer";
import ReviewDone from "../../components/ReviewDone";
import RequestSignoff from "../../components/RequestSignOff";
import RejectReco from "../../components/RejectReco";
import AttachSignOff from "../../components/AttachSignOff";
import UserDetailsCard from "../../components/UserDetailsCard";
import { useNavigate } from "react-router-dom";
import {
  downloadReport,
  getViewClickRecoData,
  setRecoId,
  setViewPartyId,
} from "store/directreconciliation/slice";
import RespondQuery from "../RespondQuery";
import IsLoadingHOC from "components/loader/loader";
import {
  DIRECT_RECO_TABLE,
  MATCHED_DATA,
  UNMATCHED_DATA,
  VIEW_UPLOAD_SCREEN,
} from "utils/constant";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -10,
    border: `1.5px solid #FFF`,
    padding: "0 4px",
    backgroundColor: "#FF0000",
  },
}));

const ActionRecoSummary = ({ setLoading }) => {
  const [message, setMessage] = useState([]);
  const [openSendReview, setOpenSendReview] = useState(false);
  const [tableCollapse, setTableCollapse] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [expand, setExpand] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [openReviwDone, setOpenReviewDone] = useState(false);
  const [openRequestSignOff, setOpenRequestSignOff] = useState(false);
  const [openRejectReco, setOpenRejectReco] = useState(false);
  const [openRespondQuery, setOpenRespondQuery] = useState(false);
  const [openAttachSignoff, setOpenAttachSignoff] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errormsg, setErrormsg] = useState(false);
  const [recoSummary, setRecoSummary] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { recoId, partyInfo, allComments, partyId, recoOnOffStatus } =
    useSelector((state) => state.trackreconciliation);

  const handleAddComment = () => {
    setOpenComment(true);
  };

  const handlePlusClick = (clickIndex) => {
    if (expand.includes(clickIndex)) {
      const expandCopy = expand.filter((element) => element !== clickIndex);
      setExpand(expandCopy);
    } else {
      const expandCopy = [...expand];
      expandCopy.push(clickIndex);
      setExpand(expandCopy);
    }
  };

  const handleReviewDone = () => {
    dispatch(statusActions("review_done")).then(() => {
      setOpenReviewDone(true);
    });
  };

  const handleRequestSignOff = () => {
    dispatch(statusActions("request_sigoff")).then(() => {
      setOpenRequestSignOff(true);
    });
  };

  const handleRejectReco = () => {
    setOpenRejectReco(true);
  };

  const handleRespondQuery = () => {
    dispatch(getQueryDetails({ recoId, partyId })).then(() => {
      setOpenRespondQuery(true);
    });
  };

  const handleAttachSignOff = () => {
    setOpenAttachSignoff(true);
  };

  const detailsData = {
    display: "flex",
    justifyContent: "space-between",
  };

  //handle View Button to redirect next screen
  const handleViewClick = (code, isUnmatchedScreen) => {
    Promise.all([
      dispatch(setCode(code)),
      dispatch(setPartyId(partyInfo.id)),
    ]).then((res) => {
      if (isUnmatchedScreen) {
        navigate(`/${DIRECT_RECO_TABLE}/${UNMATCHED_DATA}`);
      } else {
        navigate(`/${DIRECT_RECO_TABLE}/${MATCHED_DATA}`);
      }
    });
  };

  // download report click event
  const handleDownloadReport = () => {
    const status = [
      "Completed",
      "Auto_Completed",
      "Signoff_Requested",
      "Query_Raised",
      "Review_Pending",
      "Signoff_Completed",
    ];
    if (status.includes(recoSummary.recoStatus)) {
      dispatch(downloadReport({ recoId, partyId: partyInfo.id }));
    } else {
      setErrormsg(true);
    }
  };
  // error msg close event
  const handleMsgClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrormsg(false);
  };

  const signOffFlag = recoSummary?.recoStatus === "Signoff_Requested";
  const recoRejectedFlag =
    recoSummary?.recoStatus === "Reconciliation_Rejected";
  const reviewedFlag = recoSummary?.recoStatus === "Reviewed";
  const reviewPendingFlag = recoSummary?.recoStatus === "Review_Pending";
  const completedFlag =
    recoSummary?.recoStatus === "Completed" ||
    recoSummary?.recoStatus === "Auto_Completed";
  const respondQueryFlag = recoSummary?.recoStatus === "Query_Raised";

  const requestSignoffFlag = recoRejectedFlag || reviewPendingFlag;
  const rejectRecoFlag = reviewedFlag || reviewPendingFlag;
  const sendReviewFlag = completedFlag || recoRejectedFlag;
  const attachSignoffFlag = signOffFlag || respondQueryFlag;

  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    Promise.all([
      dispatch(getRecoSummary({ recoId, partyId: partyInfo.id })),
      dispatch(getAllComments({ recoId, partyId: partyInfo.id, pageNo: 0 })),
    ]).then((res) => {
      setLoading(false);
      setRecoSummary(res[0].payload);
      let dataList = res[0].payload.summaryHeaders?.filter(
        (data) => data.amount !== "0" || data.name === "Calculated Balance"
      );
      setDataList(dataList);
      switch (recoSummary.recoStatus) {
        case "Auto_Completed":
          setMessage([
            "The reconciliation has been completed. Kindly recheck and click on",
            "Send for Review",
          ]);
          break;
        case "Reconciliation_Rejected":
          setMessage([
            "The reconciliation has been rejected . Kindly resolve comments and",
            "Send for Review",
          ]);
          break;
        case "Review_Pending":
          setMessage([
            "The reconciliation is pending for review. Kindly review and click on",
            "Review Done",
          ]);
          break;
        case "Reviewed":
          setMessage([
            "The reconciliation has been reviewed. Kindly click on",
            "Request Signoff",
          ]);
          break;
        case "Signoff_Requested":
          setMessage([
            "Signoff has been requested. Kindly wait for the reply or",
            "Send Reminder",
          ]);
          break;
        case "Query_Raised":
          setMessage([
            "The query has been raised by the party. Kindly click on",
            "Respond to Query",
          ]);
          break;
        case "Sign-off_Completed":
          setMessage(["The reconciliation has been Signed off."]);
          break;
        default:
          setMessage([
            "The reconciliation has been completed. Kindly recheck and click on",
            "Send for Review",
          ]);
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refresh]);
  const handleSwitch = () => {
    dispatch(setRecoId(recoId));
    dispatch(setViewPartyId(partyInfo.id));
    dispatch(
      getViewClickRecoData({
        recoId,
        partyId: partyInfo.id,
      })
    ).then(() => {
      navigate(`/${DIRECT_RECO_TABLE}/${VIEW_UPLOAD_SCREEN}`);
    });
  };
  return (
    <>
      <Box>
        <Box
          height="60px"
          borderRadius={"7.5px"}
          bgcolor="#FFF"
          boxShadow={"0px 2px 5px rgba(0, 0, 0, 0.05)"}
          paddingX="24px"
          display={"flex"}
          alignItems={"center"}
        >
          <ArrowLeft onClick={() => navigate(-1)} />
          <Stack marginLeft="28px" marginRight={"auto"} marginTop="auto">
            <Typography
              fontFamily={"fontsfree"}
              color="#005724"
              fontSize={"14px"}
            >
              Reconciliation Summary{" "}
            </Typography>

            <Box
              height="4px"
              width={"50px"}
              borderRadius="2px"
              bgcolor={"#FF9A01"}
              marginX="auto"
              marginTop="15px"
            ></Box>
          </Stack>
          {recoOnOffStatus === "closed" && (
            <Typography
              fontFamily={"fontsfree"}
              color="#FF0000"
              fontSize={"14px"}
            >
              This Reconcilation is closed
            </Typography>
          )}
          <Box>
            {recoOnOffStatus !== "closed" && (
              <Button
                variant="contained"
                size="small"
                disableRipple
                style={{ backgroundColor: "#005724" }}
                aria-label="comments"
              >
                <StyledBadge badgeContent={allComments.length}>
                  <Typography
                    fontFamily={"fontsfree"}
                    onClick={handleAddComment}
                    sx={{ cursor: "pointer" }}
                  >
                    {allComments.length > 1 ? "Comments" : "Comment"}
                  </Typography>
                </StyledBadge>
              </Button>
            )}
            {recoOnOffStatus !== "closed" && (
              <>
                {sendReviewFlag && (
                  <Button
                    variant="contained"
                    size="small"
                    disableRipple
                    style={{ backgroundColor: "#005724", marginLeft: "16px" }}
                    aria-label="send-for-review"
                    onClick={() => {
                      setOpenSendReview(true);
                    }}
                  >
                    <Typography fontFamily={"fontsfree"}>
                      Send for Review
                    </Typography>
                  </Button>
                )}
                {reviewPendingFlag && (
                  <Button
                    variant="contained"
                    size="small"
                    disableRipple
                    onClick={handleReviewDone}
                    style={{ backgroundColor: "#005724", marginLeft: "16px" }}
                    aria-label="send-for-review"
                  >
                    <Typography fontFamily={"fontsfree"}>
                      Review Done
                    </Typography>
                  </Button>
                )}
                {reviewedFlag && (
                  <Button
                    variant="contained"
                    size="small"
                    disableRipple
                    onClick={handleRequestSignOff}
                    style={{ backgroundColor: "#005724", marginLeft: "16px" }}
                    aria-label="send-for-review"
                  >
                    <Typography fontFamily={"fontsfree"}>
                      Request Sign-off
                    </Typography>
                  </Button>
                )}
                {respondQueryFlag && (
                  <Button
                    variant="contained"
                    size="small"
                    disableRipple
                    onClick={handleRespondQuery}
                    style={{ backgroundColor: "#005724", marginLeft: "16px" }}
                    aria-label="send-for-review"
                  >
                    <Typography fontFamily={"fontsfree"}>
                      Respond to Query
                    </Typography>
                  </Button>
                )}
                {requestSignoffFlag && (
                  <Button
                    variant="contained"
                    size="small"
                    disableRipple
                    onClick={handleRequestSignOff}
                    style={{
                      border: "1px solid #FFC701",
                      backgroundColor: "#FFF",
                      marginLeft: "16px",
                    }}
                    aria-label="send-for-review"
                  >
                    <Typography fontFamily={"fontsfree"} color="#005724">
                      Request Sign-off
                    </Typography>
                  </Button>
                )}
                {rejectRecoFlag && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleRejectReco}
                    disableRipple
                    style={{
                      border: "1px solid #FFC701",
                      backgroundColor: "#FFF",
                      marginLeft: "16px",
                    }}
                    aria-label="send-for-review"
                  >
                    <Typography fontFamily={"fontsfree"} color="#005724">
                      Reject Reco
                    </Typography>
                  </Button>
                )}
                {attachSignoffFlag && (
                  <Button
                    variant="contained"
                    size="small"
                    disableRipple
                    onClick={handleAttachSignOff}
                    style={{
                      border: "1px solid #FFC701",
                      backgroundColor: "#FFF",
                      marginLeft: "16px",
                    }}
                    aria-label="send-for-review"
                  >
                    <Typography fontFamily={"fontsfree"} color="#005724">
                      Attach Sign-off
                    </Typography>
                  </Button>
                )}
              </>
            )}
            <Button
              variant="contained"
              size="small"
              disableRipple
              style={{ backgroundColor: "#ECECEC", marginLeft: "16px" }}
              endIcon={<ArrowDropDownIcon sx={{ color: "#005724" }} />}
              aria-label="more-actions"
              onClick={handleMenuClick}
            >
              <Typography fontFamily={"fontsfree"} color="#000">
                More Actions
              </Typography>
            </Button>
            <Menu
              id="menu"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                style: {
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 15px",
                },
              }}
              transformOrigin={{
                horizontal: "left",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
              }}
              sx={{ marginTop: "8px", marginLeft: "-5px" }}
            >
              <MenuItem onClick={handleDownloadReport}>
                <DownloadSummary fill={"#005724"} />
                <Typography fontFamily="fontsfree" marginLeft={2}>
                  Download Reconciliation
                </Typography>
              </MenuItem>

              {recoOnOffStatus !== "closed" && (
                <MenuItem>
                  <ReviewDoneIcon fill={"#FFFFFF"} />
                  <Typography
                    fontFamily="fontsfree"
                    marginLeft={2}
                    onClick={handleSwitch}
                  >
                    Switch to file upload screen
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
        <Box
          marginTop={"20px"}
          minHeight="60px"
          borderRadius={"7.5px"}
          bgcolor="#FFF"
          boxShadow={"0px 2px 5px rgba(0, 0, 0, 0.05)"}
          padding="24px"
        >
          <Stack
            gap={8}
            paddingY={"16px"}
            paddingX="20px"
            direction="row"
            borderRadius="10px"
            border="5px solid #F3F3F3"
          >
            <Stack gap={2} width="100%">
              <Box sx={detailsData}>
                <Typography>Reco Date/Period</Typography>
                <Typography fontWeight="bold">
                  {`${recoSummary?.reconPeriodFrom} to ${recoSummary?.reconPeriodTo}`}
                </Typography>
              </Box>
              <Box sx={detailsData}>
                <Typography>Reco ID</Typography>
                <Typography fontWeight="bold">{recoSummary?.recoId}</Typography>
              </Box>
              <Box sx={detailsData}>
                <Typography>Reco Status</Typography>
                <Typography fontWeight="bold">
                  {recoSummary?.recoStatus}
                </Typography>
              </Box>
            </Stack>
            <Stack gap={2} width="100%">
              <Box sx={detailsData}>
                <Typography>Reco Type</Typography>
                <Typography
                  fontWeight="bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {recoSummary?.recoType}
                </Typography>
              </Box>
              <Box sx={detailsData}>
                <Typography>Party Type</Typography>
                <Typography fontWeight="bold">
                  {recoSummary?.partyType}
                </Typography>
              </Box>
              <Box sx={detailsData}>
                <Typography>Updated At</Typography>
                <Typography fontWeight="bold">
                  {recoSummary?.updatedAt === null
                    ? `Not yet updated`
                    : recoSummary?.updatedAt}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            height={"30px"}
            marginY="16px"
            direction="row"
            bgcolor="#FFC701"
            borderRadius={"7.5px"}
            alignItems="center"
            gap={1}
          >
            <Typography marginLeft={"auto"} fontFamily="SFProdisplay">
              {message[0]}
            </Typography>
            {message[1] && (
              <Typography
                marginRight={"auto"}
                fontFamily="fontsfree"
                sx={{ textDecoration: "underline" }}
              >
                {message[1]}.
              </Typography>
            )}
          </Stack>
          {/* Reco Summary Table */}
          <table className="table-style">
            <thead>
              <tr>
                <th
                  style={{ borderTopLeftRadius: "7.5px", cursor: "pointer" }}
                  onClick={() => {
                    setTableCollapse(!tableCollapse);
                    tableCollapse
                      ? setExpand([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
                      : setExpand([]);
                  }}
                >
                  &nbsp;&nbsp;
                </th>
                <th
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setTableCollapse(!tableCollapse);
                    tableCollapse
                      ? setExpand([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
                      : setExpand([]);
                  }}
                >
                  {<CollapseIcon />}
                </th>
                <th style={{ width: "50%" }}>Particulars</th>
                <th
                  style={{
                    textAlign: "right",
                    width: "min-content",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    textAlign: "center",
                    width: "min-content",
                  }}
                >
                  No. of Entries
                </th>
                <th style={{ textAlign: "center" }}>Action</th>
                <th style={{ borderTopRightRadius: "7.5px" }}>&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {(dataList ?? []).map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr
                      style={{
                        borderBottom:
                          index !== dataList.length - 1 &&
                          "0.5px solid #BBBBBB",
                        backgroundColor:
                          index === 0 || index === dataList.length - 1
                            ? "#FFFAD0"
                            : index % 2 !== 0
                            ? "#FFF"
                            : "#F6F6F6",
                      }}
                    >
                      <td>&nbsp;&nbsp;</td>
                      {index === dataList.length - 1 ? (
                        <td></td>
                      ) : (
                        <td
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => handlePlusClick(index)}
                        >
                          {!expand.includes(index) ? (
                            <PlusIcon />
                          ) : (
                            <MinusIcon />
                          )}
                        </td>
                      )}
                      <td style={{ textAlign: "left" }}>{item.name}</td>
                      <td
                        style={{
                          textAlign: "right",
                          width: "min-content",
                        }}
                      >
                        {item.amount}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          width: "min-content",
                        }}
                      >
                        {item.numberOfEntries}
                      </td>
                      {index === dataList.length - 1 ? (
                        <td></td>
                      ) : (
                        <td
                          style={{
                            color: "#005724",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            fontFamily={"fontsfree"}
                            fontSize="14px"
                            onClick={() =>
                              handleViewClick(item.code, item.unmatchedScreen)
                            }
                          >
                            View
                          </Typography>
                        </td>
                      )}
                      <td>&nbsp;&nbsp;</td>
                    </tr>
                    {expand.includes(index) &&
                      item.records
                        ?.filter((record) => record.amount !== "0")
                        .map((record, i) => {
                          return (
                            <tr
                              key={i}
                              style={{
                                borderBottom: "0.5px solid #BBBBBB",
                                background: "#F7F7F7",
                              }}
                            >
                              <td
                                style={{
                                  background:
                                    "linear-gradient(90deg, #D0D0D0 0%, rgba(255, 255, 255, 0) 100%)",
                                }}
                              >
                                &nbsp;&nbsp;
                              </td>
                              <td
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              ></td>
                              <td style={{ textAlign: "left" }}>
                                {record.name}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  width: "min-content",
                                }}
                              >
                                {record.amount}
                              </td>
                              <td>{record.noOfRecords}</td>
                              <td
                                style={{
                                  color: "#005724",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                <Typography
                                  fontFamily={"fontsfree"}
                                  fontSize="14px"
                                  onClick={() =>
                                    handleViewClick(
                                      record.code,
                                      record.unmatchedScreen
                                    )
                                  }
                                >
                                  View
                                </Typography>
                              </td>
                              <td
                                style={{
                                  background:
                                    "linear-gradient(270deg, #D0D0D0 0%, rgba(255, 255, 255, 0) 100%)",
                                }}
                              >
                                &nbsp;&nbsp;
                              </td>
                            </tr>
                          );
                        })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <Typography fontFamily={"fontsfree"} marginY="21px">
            Reconciliation Analytics
          </Typography>
          {/* Reconciliation Analytics Table */}
          <table className="table2-style">
            <thead>
              <tr
                style={{
                  borderBottom: "0.5px solid #BBBBBB",
                }}
              >
                <th>&nbsp;&nbsp;</th>
                <th style={{ textAlign: "left" }}>Particulars</th>
                <th>Company Numbers</th>
                <th>Company Percentage</th>
                <th>Party Numbers</th>
                <th>Party Percentage</th>
                <th>Action</th>
                <th>&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {recoSummary.summaryAnalytics
                ?.filter(
                  (data) =>
                    data.companyNumbers !== 0 ||
                    data.companyPercentage !== "0" ||
                    data.partyNumbers !== 0 ||
                    data.partyPercentage !== "0"
                )
                ?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom:
                        index !== recoSummary.summaryAnalytics.length - 1 &&
                        "0.5px solid #BBBBBB",
                      backgroundColor:
                        index === recoSummary.summaryAnalytics.length - 1
                          ? "#FFFAD0"
                          : index % 2 !== 0
                          ? "#FFF"
                          : "#F6F6F6",
                    }}
                  >
                    <td>&nbsp;&nbsp;</td>
                    <td
                      style={{
                        textAlign: "left",
                        width: "min-content",
                      }}
                    >
                      {item.analyticsStatus}
                    </td>
                    <td>{item.companyNumbers}</td>
                    <td>{item.companyPercentage}</td>
                    <td>{item.partyNumbers}</td>
                    <td>{item.partyPercentage}</td>
                    {index === recoSummary.summaryAnalytics.length - 1 ? (
                      <td></td>
                    ) : (
                      <td
                        style={{
                          color: "#005724",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleViewClick(item.code, item.unmatchedScreen)
                        }
                      >
                        {item.analyticsStatus !== "Total" && (
                          <Typography fontFamily={"fontsfree"} fontSize="14px">
                            View
                          </Typography>
                        )}
                      </td>
                    )}
                    <td>&nbsp;&nbsp;</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Box>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          marginTop={"28px"}
          width="100%"
        >
          <UserDetailsCard
            icon={<PreparedByIcon fill="#85CDA3" />}
            info={recoSummary.summaryPreparedBy}
            pending="Prepare Pending"
            title="Prepared By"
            pendingIcon={<PreparedByIcon fill="#B1B1B1" />}
          />
          <UserDetailsCard
            icon={<ReviewedByIcon fill="#85CDA3" />}
            info={recoSummary.summaryReviewedBy}
            pending="Review Pending"
            title="Reviewed By"
            pendingIcon={<ReviewedByIcon fill="#B1B1B1" />}
          />
          <UserDetailsCard
            icon={<SignOffByIcon fill="#85CDA3" />}
            info={recoSummary.summarySignedBy}
            pending="Sign off Pending"
            title="Signed off By"
            pendingIcon={<SignOffByIcon fill="#B1B1B1" />}
          />
        </Stack>
      </Box>
      <Snackbar
        open={errormsg}
        autoHideDuration={2000}
        onClose={handleMsgClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleMsgClose}
          severity="error"
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          "Reconciliation is not completed"
        </Alert>
      </Snackbar>
      <SendForReview
        setOpen={setOpenSendReview}
        open={openSendReview}
        isAllSelected={false}
        partyId={partyInfo.id}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <ReviewDone
        setOpen={setOpenReviewDone}
        open={openReviwDone}
        partyId={partyInfo.id}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <RequestSignoff
        setOpen={setOpenRequestSignOff}
        open={openRequestSignOff}
        refresh={refresh}
        setRefresh={setRefresh}
        partyId={partyInfo.id}
      />
      <RejectReco
        partyInfo={partyInfo.id}
        recoId={recoId}
        setOpen={setOpenRejectReco}
        open={openRejectReco}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <RespondQuery
        setOpen={setOpenRespondQuery}
        open={openRespondQuery}
        refresh={refresh}
        setRefresh={setRefresh}
        recoId={recoId}
        partyId={partyInfo.id}
      />
      <AttachSignOff
        partyInfo={partyInfo.id}
        setOpen={setOpenAttachSignoff}
        open={openAttachSignoff}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <CommentDrawer
        partyInfo={partyInfo}
        recoId={recoId}
        setOpen={setOpenComment}
        open={openComment}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default IsLoadingHOC(ActionRecoSummary);
