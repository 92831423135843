import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosApi from "../../helper/axiosApi";
import state from "./state.js";

// to get display settings for right side section
export const getCompanySettings = createAsyncThunk(
  "getCompanySettings",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        // `/settings/api/v1/settings/company-settings`
        `/settings/api/v1/settings/company-settings/by-companyid`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// send all data on create click
export const updateCompanySettings = createAsyncThunk(
  "updateCompanySettings",
  async ({ data, settingId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/settings/api/v1/settings/${settingId}`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const companySettingSlice = createSlice({
  name: "companySettingSlice",
  initialState: state,
  extraReducers: {
    //to get company settings
    [getCompanySettings.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_COMPANY_SETTINGS";
    },
    [getCompanySettings.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_COMPANY_SETTINGS";
      state.companySettingsData = action.payload;
    },
    [getCompanySettings.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_COMPANY_SETTINGS";
      state.error = action.payload;
    },
    //update company settings
    [updateCompanySettings.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_COMPANY_SETTINGS";
    },
    [updateCompanySettings.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_COMPANY_SETTINGS";
    },
    [updateCompanySettings.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_COMPANY_SETTINGS";
      state.error = action.payload;
    },
  },
  reducers: {
    setAmountToleranceList(state, action) {
      state.amountToleranceList = action.payload;
    },
    setAddedAmountToleranceList(state, action) {
      state.addedAmountToleranceList = action.payload;
    },
  },
});

export const { setAmountToleranceList, setAddedAmountToleranceList } =
  companySettingSlice.actions;

const { reducer } = companySettingSlice;

export default reducer;
