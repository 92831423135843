import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import state from "./state";
import axiosApi from "helper/axiosApi";

//to get all rule groups
export const getAllRuleGroups = createAsyncThunk(
  "getAllRuleGroups",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconrule/api/v2/rulegroups/?pageNo=${page}&pageSize=${pageSize}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get single rule group data
export const getRuleGroupData = createAsyncThunk(
  "getRuleGroupData",
  async ({ ruleGroupID }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconrule/api/v2/rulegroups/${ruleGroupID}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get rule types
export const getRuleTypes = createAsyncThunk(
  "getRuleTypes",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconrule/api/v1/masterdata/ruletypes`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get enitity types
export const getEntityTypes = createAsyncThunk(
  "getEntityTypes",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconrule/api/v1/masterdata/entitytypes`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get classifications
export const getClassifications = createAsyncThunk(
  "getClassifications",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/masterdata/classifications`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get classifications
export const getStatuses = createAsyncThunk(
  "getStatuses",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/recon/api/v1/masterdata/status`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get doc types
export const getDocTypes = createAsyncThunk(
  "getDocTypes",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/recon/api/v1/masterdata/doctypes`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get record selections
export const getRecorSelections = createAsyncThunk(
  "getRecorSelections",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconrule/api/v1/masterdata/recordselections`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get search conditions
export const getSearchConditions = createAsyncThunk(
  "getSearchConditions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconrule/api/v1/masterdata/searchconditions`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get post conditions
export const getPostConditions = createAsyncThunk(
  "getPostConditions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconrule/api/v1/masterdata/postconditions`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get All companies with assigned rule group
export const getAllCompanies = createAsyncThunk(
  "getAllCompanies",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconrule/api/v2/company-mappings`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//activate or deactivate rule
export const ruleActivationApi = createAsyncThunk(
  "ruleActivationApi",
  async ({ ruleId, active }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconrule/api/v2/rules/${ruleId}/${
          active ? "deactivate" : "activate"
        }`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//update rule order
export const updateRuleOrder = createAsyncThunk(
  "updateRuleOrder",
  async ({ ruleGrpId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconrule/api/v2/rulegroups/${ruleGrpId}/update-ruleorder`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const settingSlice = createSlice({
  name: "schedulerSlice",
  initialState: state,
  extraReducers: {
    //to get rule groups
    [getAllRuleGroups.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_RULE_GROUPS";
    },
    [getAllRuleGroups.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_RULE_GROUPS";
      state.ruleGroups = action.payload;
    },
    [getAllRuleGroups.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_RULE_GROUPS";
      state.error = action.payload;
    },
    //to get rule groups
    [getRuleGroupData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_RULE_GROUP_DATA";
    },
    [getRuleGroupData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_RULE_GROUP_DATA";
      state.ruleGrpData = action.payload;
      state.ruleGroupName = action.payload.groupName;
    },
    [getRuleGroupData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_RULE_GROUP_DATA";
      state.error = action.payload;
    },
    //to get rule types
    [getRuleTypes.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_RULE_TYPES";
    },
    [getRuleTypes.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_RULE_TYPES";
    },
    [getRuleTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_RULE_TYPES";
      state.error = action.payload;
    },
    //to get entity types
    [getEntityTypes.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ENTITY_TYPES";
    },
    [getEntityTypes.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ENTITY_TYPES";
    },
    [getEntityTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ENTITY_TYPES";
      state.error = action.payload;
    },
    //to get classifications
    [getEntityTypes.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_CLASSIFICATIONS";
    },
    [getEntityTypes.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_CLASSIFICATIONS";
    },
    [getEntityTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_CLASSIFICATIONS";
      state.error = action.payload;
    },
    //to get stauses
    [getStatuses.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATUSES";
    },
    [getStatuses.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATUSES";
    },
    [getStatuses.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATUSES";
      state.error = action.payload;
    },
    //to get doc types
    [getDocTypes.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DOCTYPES";
    },
    [getDocTypes.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DOCTYPES";
    },
    [getDocTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DOCTYPES";
      state.error = action.payload;
    },
    //to get doc types
    [getRecorSelections.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_RECORD_SELECTIONS";
    },
    [getRecorSelections.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_RECORD_SELECTIONS";
    },
    [getRecorSelections.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_RECORD_SELECTIONS";
      state.error = action.payload;
    },
    //to get search conditions
    [getSearchConditions.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SEARCH_CONDITIONS";
    },
    [getSearchConditions.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SEARCH_CONDITIONS";
    },
    [getSearchConditions.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SEARCH_CONDITIONS";
      state.error = action.payload;
    },
    //to get post conditions
    [getSearchConditions.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_POST_CONDITIONS";
    },
    [getSearchConditions.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_POST_CONDITIONS";
    },
    [getSearchConditions.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_POST_CONDITIONS";
      state.error = action.payload;
    },
    //to get All companies with assigned rule group
    [getAllCompanies.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_POST_CONDITIONS";
    },
    [getAllCompanies.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_POST_CONDITIONS";
    },
    [getAllCompanies.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_POST_CONDITIONS";
      state.error = action.payload;
    },
    //activate or deactivate rule
    [ruleActivationApi.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ACTIVATE_DEACTIVATE_RULE";
    },
    [ruleActivationApi.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ACTIVATE_DEACTIVATE_RULE";
    },
    [ruleActivationApi.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ACTIVATE_DEACTIVATE_RULE";
      state.error = action.payload;
    },
    //update rule order
    [updateRuleOrder.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_RULE_ORDER";
    },
    [updateRuleOrder.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_RULE_ORDER";
    },
    [updateRuleOrder.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_RULE_ORDER";
      state.error = action.payload;
    },
  },
  reducers: {
    setRuleGroupData(state, action) {
      state.ruleGrpData = action.payload;
    },
    setActiveValue(state, action) {
      state.activeValue = action.payload;
    },
    setPreList(state, action) {
      state.preList = action.payload;
    },
    setPrimaryList(state, action) {
      state.primaryList = action.payload;
    },
    setSecondaryList(state, action) {
      state.secondaryList = action.payload;
    },
    setPostList(state, action) {
      state.postList = action.payload;
    },
    setRuleGroupId(state, action) {
      state.ruleGrpId = action.payload;
    },
  },
});

export const {
  setRuleGroupData,
  setActiveValue,
  setPreList,
  setPrimaryList,
  setSecondaryList,
  setPostList,
  setRuleGroupId,
} = settingSlice.actions;

const { reducer } = settingSlice;

export default reducer;
