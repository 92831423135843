import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setErrorSnackbar, setIsTokenExpire } from "store/masterdata/slice";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosApi = axios.create({
  baseURL: baseURL,
  headers: {
    common: {},
  },
});

function AxiosInterceptor({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Add a request interceptor
    axiosApi.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        const tempToken = localStorage.getItem("tempToken");
        if (tempToken === null) {
          dispatch(setErrorSnackbar(true));
          // navigate(`/${LOGGED_OUT}`);
          return;
        }
        config.headers.Authorization = tempToken;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axiosApi.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const tempToken = localStorage.getItem("tempToken");
        if (tempToken !== null && error.response.status === 403) {
          dispatch(setIsTokenExpire(true));
          return;
        } else if (
          error.response.status > 399 ||
          error.response.status === 403
        ) {
          dispatch(setErrorSnackbar(true));
        } else {
          return Promise.reject(error);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  return children;
}

export default axiosApi;
export { AxiosInterceptor };
