import React from "react";
import styled from "styled-components/macro";
import { Stack, Tooltip, Typography, tooltipClasses } from "@mui/material";
import SidebarNavList from "./SidebarNavList";
import { BackIcon } from "components/AllSvg";
import { useSelector } from "react-redux";
import { styled as customStyle } from "@mui/system";

const Title = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0;
  text-transform: uppercase;
  display: block;
`;

const CustomTooltip = customStyle(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000000",
    color: "white",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000000",
  },
}));

const SidebarNavSection = (props) => {
  const {
    title,
    pages,
    className,
    component: Component = "nav",
    ...rest
  } = props;

  const { isMiniSidebar } = useSelector((state) => state.trackreconciliation);

  return (
    <Component {...rest}>
      {title && (
        <Title variant="subtitle2" fontFamily={"SFProdisplay"}>
          {title}
        </Title>
      )}
      <SidebarNavList pages={pages} depth={0} />
      <Stack
        direction={"row"}
        onClick={() => {
          window.location = process.env.REACT_APP_V1_URL;
        }}
        style={{ cursor: "pointer", padding: "20px" }}
      >
        <CustomTooltip
          title={isMiniSidebar ? "Back To Firmway" : ""}
          placement="right"
          arrow
        >
          <Typography>
            <BackIcon />
          </Typography>
        </CustomTooltip>

        {!isMiniSidebar && (
          <Typography
            color={"white"}
            style={{ marginLeft: "20px" }}
            fontFamily={"SFProdisplay"}
          >
            Back To Firmway
          </Typography>
        )}
      </Stack>
    </Component>
  );
};

export default SidebarNavSection;
