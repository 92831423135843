import React from "react";

const NoSftpImg = () => {
  return (
    <svg
      width="261"
      height="118"
      viewBox="0 0 261 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9897_25)">
        <path d="M252.13 117.428H0V117.951H252.13V117.428Z" fill="#3F3D56" />
        <path
          d="M39.7245 107.801C43.928 107.801 47.3357 100.843 47.3357 92.2601C47.3357 83.6769 43.928 76.7188 39.7245 76.7188C35.5209 76.7188 32.1133 83.6769 32.1133 92.2601C32.1133 100.843 35.5209 107.801 39.7245 107.801Z"
          fill="#3F3D56"
        />
        <path
          d="M40.4261 117.699C37.3778 99.3955 40.3954 81.1585 40.4263 80.9766L41.0192 81.0771C40.9885 81.2581 37.9882 99.3993 41.0194 117.6L40.4261 117.699Z"
          fill="#C2C2C2"
        />
        <path
          d="M46.4236 88.9185L39.541 92.6025L39.8247 93.1333L46.7073 89.4492L46.4236 88.9185Z"
          fill="#C2C2C2"
        />
        <path
          d="M32.5612 90.7642L32.2773 91.2949L39.1591 94.9811L39.443 94.4504L32.5612 90.7642Z"
          fill="#C2C2C2"
        />
        <path
          d="M21.4354 98.6887C29.6571 98.6887 36.3221 85.0795 36.3221 68.2916C36.3221 51.5038 29.6571 37.8945 21.4354 37.8945C13.2138 37.8945 6.54883 51.5038 6.54883 68.2916C6.54883 85.0795 13.2138 98.6887 21.4354 98.6887Z"
          fill="#005724"
        />
        <path
          d="M23.0933 118C17.139 82.2484 23.0333 46.6271 23.0935 46.2715L23.6864 46.372C23.6264 46.7267 17.7495 82.2522 23.6866 117.901L23.0933 118Z"
          fill="#C2C2C2"
        />
        <path
          d="M34.6787 62.009L21.2168 69.2148L21.5005 69.7456L34.9624 62.5398L34.6787 62.009Z"
          fill="#C2C2C2"
        />
        <path
          d="M7.29362 65.6187L7.00977 66.1494L20.4696 73.359L20.7535 72.8283L7.29362 65.6187Z"
          fill="#C2C2C2"
        />
        <path
          d="M56.6934 91.5924C67.9441 91.5924 77.0646 72.9693 77.0646 49.9964C77.0646 27.0236 67.9441 8.40039 56.6934 8.40039C45.4427 8.40039 36.3223 27.0236 36.3223 49.9964C36.3223 72.9693 45.4427 91.5924 56.6934 91.5924Z"
          fill="#005724"
        />
        <path
          d="M59.0719 118C50.927 69.0947 58.9897 20.3683 59.0721 19.8818L59.665 19.9823C59.5828 20.4679 51.5374 69.0985 59.6652 117.901L59.0719 118Z"
          fill="#C2C2C2"
        />
        <path
          d="M74.8667 41.4968L56.4453 51.3574L56.729 51.8882L75.1504 42.0276L74.8667 41.4968Z"
          fill="#C2C2C2"
        />
        <path
          d="M37.2878 46.4371L37.0039 46.9678L55.4227 56.8332L55.7066 56.3025L37.2878 46.4371Z"
          fill="#C2C2C2"
        />
        <path
          d="M227.826 107.801C232.03 107.801 235.437 100.843 235.437 92.2601C235.437 83.6769 232.03 76.7188 227.826 76.7188C223.623 76.7188 220.215 83.6769 220.215 92.2601C220.215 100.843 223.623 107.801 227.826 107.801Z"
          fill="#3F3D56"
        />
        <path
          d="M227.123 117.699C230.171 99.3955 227.153 81.1585 227.122 80.9766L226.529 81.0771C226.56 81.2581 229.56 99.3993 226.529 117.6L227.123 117.699Z"
          fill="#C4C4C4"
        />
        <path
          d="M221.126 88.9184L220.842 89.4492L227.724 93.1333L228.008 92.6025L221.126 88.9184Z"
          fill="#C4C4C4"
        />
        <path
          d="M234.987 90.764L228.105 94.4502L228.389 94.9809L235.271 91.2947L234.987 90.764Z"
          fill="#C4C4C4"
        />
        <path
          d="M246.113 98.6887C254.335 98.6887 261 85.0795 261 68.2916C261 51.5038 254.335 37.8945 246.113 37.8945C237.892 37.8945 231.227 51.5038 231.227 68.2916C231.227 85.0795 237.892 98.6887 246.113 98.6887Z"
          fill="#005724"
        />
        <path
          d="M244.455 118C250.409 82.2484 244.515 46.6271 244.454 46.2715L243.861 46.372C243.922 46.7267 249.798 82.2522 243.861 117.901L244.455 118Z"
          fill="#B8B8B8"
        />
        <path
          d="M232.872 62.0093L232.588 62.54L246.05 69.7459L246.333 69.2151L232.872 62.0093Z"
          fill="#B8B8B8"
        />
        <path
          d="M260.257 65.6186L246.797 72.8281L247.081 73.3588L260.541 66.1493L260.257 65.6186Z"
          fill="#B8B8B8"
        />
        <path
          d="M210.856 91.5924C222.106 91.5924 231.227 72.9693 231.227 49.9964C231.227 27.0236 222.106 8.40039 210.856 8.40039C199.605 8.40039 190.484 27.0236 190.484 49.9964C190.484 72.9693 199.605 91.5924 210.856 91.5924Z"
          fill="#005724"
        />
        <path
          d="M208.478 118C216.623 69.0947 208.56 20.3683 208.478 19.8818L207.885 19.9823C207.967 20.4679 216.013 69.0985 207.885 117.901L208.478 118Z"
          fill="#B8B8B8"
        />
        <path
          d="M192.682 41.4965L192.398 42.0273L210.82 51.8879L211.104 51.3571L192.682 41.4965Z"
          fill="#B8B8B8"
        />
        <path
          d="M230.263 46.4363L211.844 56.3018L212.128 56.8325L230.546 46.967L230.263 46.4363Z"
          fill="#B8B8B8"
        />
        <path
          d="M154.217 26.5587L156.625 24.6315C154.754 24.425 153.986 25.4459 153.671 26.2539C152.21 25.6467 150.619 26.4425 150.619 26.4425L155.437 28.1928C155.194 27.5431 154.771 26.9763 154.217 26.5587Z"
          fill="#3F3D56"
        />
        <path
          d="M95.3656 9.54504L97.7734 7.61781C95.9029 7.41129 95.1343 8.43219 94.8198 9.24024C93.3584 8.63298 91.7676 9.42883 91.7676 9.42883L96.5852 11.1791C96.3422 10.5294 95.9191 9.96261 95.3656 9.54504Z"
          fill="#3F3D56"
        />
        <path
          d="M191.358 1.95422L193.766 0.0269897C191.895 -0.179533 191.126 0.84137 190.812 1.64942C189.351 1.04216 187.76 1.83801 187.76 1.83801L192.577 3.58827C192.334 2.93863 191.911 2.37179 191.358 1.95422Z"
          fill="#3F3D56"
        />
        <path
          d="M196.22 114.399C195.579 114.399 194.951 114.568 194.397 114.889C193.834 114.38 193.136 114.045 192.386 113.925C191.637 113.805 190.869 113.906 190.176 114.214C189.483 114.523 188.894 115.026 188.481 115.663C188.068 116.3 187.849 117.043 187.85 117.802H199.868C199.803 116.878 199.39 116.014 198.713 115.382C198.036 114.751 197.145 114.4 196.22 114.399Z"
          fill="#3F3D56"
        />
        <path
          d="M74.0711 114.399C73.431 114.399 72.8022 114.568 72.2481 114.889C71.6854 114.38 70.9871 114.045 70.2379 113.925C69.4887 113.805 68.7209 113.906 68.0276 114.214C67.3344 114.523 66.7455 115.026 66.3326 115.663C65.9196 116.3 65.7002 117.043 65.7012 117.802H77.7197C77.6542 116.878 77.2414 116.014 76.5644 115.382C75.8874 114.751 74.9965 114.4 74.0711 114.399Z"
          fill="#3F3D56"
        />
        <path
          d="M136.585 114.399C135.945 114.399 135.316 114.568 134.762 114.889C134.199 114.38 133.501 114.045 132.752 113.925C132.002 113.805 131.235 113.906 130.541 114.214C129.848 114.523 129.259 115.026 128.846 115.663C128.433 116.3 128.214 117.043 128.215 117.802H140.233C140.168 116.878 139.755 116.014 139.078 115.382C138.401 114.751 137.51 114.4 136.585 114.399Z"
          fill="#3F3D56"
        />
        <path
          d="M123.393 105.808H88.7188V106.927H94.1265V117.561H95.2449V106.927H116.308V117.561H117.426V106.927H123.393V105.808Z"
          fill="#3F3D56"
        />
        <path
          d="M123.462 102.459H88.7871V103.578H123.462V102.459Z"
          fill="#3F3D56"
        />
        <path
          d="M123.462 99.6602H88.7871V100.78H123.462V99.6602Z"
          fill="#3F3D56"
        />
        <path
          d="M123.462 96.8623H88.7871V97.9817H123.462V96.8623Z"
          fill="#3F3D56"
        />
        <path
          d="M177.536 105.808H142.861V106.927H148.269V117.561H149.388V106.927H170.45V117.561H171.569V106.927H177.536V105.808Z"
          fill="#3F3D56"
        />
        <path
          d="M177.604 102.459H142.93V103.578H177.604V102.459Z"
          fill="#3F3D56"
        />
        <path
          d="M177.604 99.6602H142.93V100.78H177.604V99.6602Z"
          fill="#3F3D56"
        />
        <path
          d="M177.604 96.8623H142.93V97.9817H177.604V96.8623Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0_9897_25">
          <rect width="261" height="118" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoSftpImg;
