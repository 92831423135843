import React, { useState, useEffect } from "react";
import "assets/styles/matched.css";
import "assets/styles/actionviewtrackertable.css";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  Pagination,
  Stack,
  MenuItem,
  TextField,
  Button,
  PaginationItem,
  Typography,
  Select,
  Snackbar,
  Alert,
  ListItemText,
  Menu,
  ListItemIcon,
  IconButton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styled from "styled-components/macro";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import {
  setPartyIds,
  setSort,
  setPartyDataIds,
  setCompanyDataIds,
  getViewRemarkData,
  getRecordsToViewActionTrackerRecords,
  setActionViewTrackerSelected,
  getActionTrackerActionOwnerStatusList,
  getActionTrackerActionTakenStatusFilterList,
  getActionTrackerReconciliationStatusFilterList,
  downloadFileViewActionTrackerTable,
  setUnselected,
  setSelected,
} from "../../../store/requeststatement/slice";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import {
  SortLabel,
  LinkIcon,
  DownloadSummary,
  FilterIcon,
  CloseIcon,
} from "components/AllSvg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IsLoadingHOC from "components/loader/loader";
import ActionBulkActions from "./ActionBulkActions";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { alpha } from "@mui/material/styles";
import ActionTrackerUpdateActionTakenModal from "./ModalComponents/ActionTrackerUpdateActionTakenModal";
import ActionTrackerAddViewRemarkModal from "./ModalComponents/ActionTrackerAddViewRemarkModal";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const cancelStyle = {
  width: "100px",
  height: "25px",
  fontSize: "12px",
  fontWeight: "700",
  background: "#FFFFFF",
  color: "#005724",
  border: "1px solid #FF9A01",
  boxSizing: "border-box",
  borderRadius: "5px",
  marginTop: "10px",
  padding: "5px",
};

const applybtnStyle = {
  width: "100px",
  height: "25px",
  background: "#005724",
  borderRadius: "5px",
  color: "#FFFFFF",
  marginTop: "10px",
  padding: "5px",
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.Mui-selected": {
    backgroundColor: "#deffeb",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#deffeb",
  },
}));

const headCells = [
  {
    id: "partyCode",
    disablePadding: false,
    label: "Party Code",
  },
  {
    id: "partyName",
    disablePadding: false,
    label: "Party Name",
  },
  {
    id: "actionStatus",
    disablePadding: false,
    label: "Action Taken Status",
  },
  {
    id: "actionOwner",
    disablePadding: false,
    label: "Action Owner",
  },
  {
    id: "pendingSince",
    disablePadding: false,
    label: "Pending Since",
  },
  {
    id: "invoiceDate",
    disablePadding: false,
    label: "Invoice Date",
  },
  {
    id: "invoiceNumber",
    disablePadding: false,
    label: "Invoice Number",
  },
  {
    id: "invoiceCount",
    disablePadding: false,
    label: "Invoice Count",
  },
  {
    id: "documentType",
    disablePadding: false,
    label: "Document Type",
  },
  {
    id: "amount",
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "classification",
    disablePadding: false,
    label: "Classification",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, isAllSelected } = props;
  const dispatch = useDispatch();
  const { sort } = useSelector((state) => state.trackreconciliation);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none"></TableCell>
        <TableCell padding="none">
          <Checkbox
            disableRipple
            color="success"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={isAllSelected}
            onChange={onSelectAllClick}
            size="small"
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={
              sort.id === headCell.id
                ? sort.direction === "ascending"
                  ? "asc"
                  : "desc"
                : "asc"
            }
          >
            <TableSortLabel
              IconComponent={() => (
                <Box sx={{ marginTop: "15px" }}>
                  <SortLabel />
                </Box>
              )}
              active={headCell.id === sort.id}
              direction={
                sort.id === headCell.id
                  ? sort.direction === "ascending"
                    ? "asc"
                    : "desc"
                  : "asc"
              }
              onClick={() =>
                dispatch(
                  setSort({
                    id: headCell.id,
                    direction:
                      sort.direction === "ascending"
                        ? "descending"
                        : "ascending",
                  })
                )
              }
            >
              {headCell.label}
              {sort.id === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sort.direction === "ascending"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// main table
function ActionViewTrackerTable({ setLoading }) {
  const dispatch = useDispatch();
  const {
    selected,
    actionViewTrackerSelected,
    sort,
    recoId,
    partyDataIds,
    companyDataIds,
    recoOnOffStatus,
    viewActionTrackerData,
    actionTakenStatusforViewActionTracker,
    uncheckedDataIds,
  } = useSelector((state) => state.trackreconciliation);

  const buttonSX = {
    marginRight: "12px",
    backgroundColor: "#ececec",
    color: "#005724",
  };

  const buttonSelected = {
    marginRight: "12px",
    backgroundColor: "#005724",
    color: "#ececec",
  };

  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
        marginLeft: "60px",
      },
    },
  };

  const [rowData, setRowData] = useState([]);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [openUpdateActionTaken, setOpenUpdateActionTaken] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refresh, setRefresh] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [search, setSearch] = useState("");
  const [searchString, setSearchString] = useState("");

  const [openActionTrackerAddViewRemark, setopenActionTrackerAddViewRemark] =
    useState(false); //open add view remark popup

  const [
    gettingUpdatedActionTakenStatusList,
    setgettingUpdatedActionTakenStatusList,
  ] = useState([]); //for action taken status dropdown change-selected values

  const [
    gettingUpdatedReconciliationStatusList,
    setgettingUpdatedReconciliationStatusList,
  ] = useState([]); //for reconciliation status dropdown change-selected values

  const [
    gettingUpdatedActionOwnerStatusList,
    setgettingUpdatedActionOwnerStatusList,
  ] = useState([]); //for action owner status dropdown change-selected values

  const [anchorEl, setAnchorEl] = useState(null);
  const [openSelectBox, setopenSelectBox] = useState(false); //open action taken status dropdown box

  const [
    openReconciliationStatusSelectBox,
    setopenReconciliationStatusSelectBox,
  ] = useState(false); //open reconciliation status dropdown box

  const [openActionOwnerFilterBox, setopenActionOwnerFilterBox] =
    useState(false); //open action owner status dropdown box

  const [
    actionTrackerReconciliationStatusFilterList,
    setActionTrackerReconciliationStatusFilterList,
  ] = useState([]); //for reconciliation status dropdown list

  const [
    actionTrackerActionTakenStatusFilterList,
    setActionTrackerActionTakenStatusFilterList,
  ] = useState([]); //for action Taken status dropdown list

  const [
    actionTrackerActionOwnerStatusFilterList,
    setActionTrackerActionOwnerStatusFilterList,
  ] = useState([]); //for action owner status dropdown list

  //action taken status select all
  const isAllActionStatusSelected =
    actionTrackerActionTakenStatusFilterList.length > 0 &&
    gettingUpdatedActionTakenStatusList.length ===
      actionTrackerActionTakenStatusFilterList.length;
  //reconciliation select all
  const isAllReconciliationStatusSelected =
    actionTrackerReconciliationStatusFilterList.length > 0 &&
    gettingUpdatedReconciliationStatusList.length ===
      actionTrackerReconciliationStatusFilterList.length;
  //action owner select all
  const isAllActionOwnerStatusSelected =
    actionTrackerActionOwnerStatusFilterList.length > 0 &&
    gettingUpdatedActionOwnerStatusList.length ===
      actionTrackerActionOwnerStatusFilterList.length;

  const open = Boolean(anchorEl);
  let newSelected = [];
  let newPartyIdSelected = [];
  let newCompanyIdSelected = [];

  //open snackbar
  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };
  //close snacknbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    dispatch(setActionViewTrackerSelected([]));
  }, [dispatch]);

  //For All dropdown
  useEffect(() => {
    Promise.all([
      dispatch(getActionTrackerReconciliationStatusFilterList()),
      dispatch(getActionTrackerActionTakenStatusFilterList()),
      dispatch(getActionTrackerActionOwnerStatusList({ recoId })),
    ]).then((res) => {
      setActionTrackerReconciliationStatusFilterList(res[0]?.payload?.data);
      setActionTrackerActionTakenStatusFilterList(res[1]?.payload?.data);
      setActionTrackerActionOwnerStatusFilterList(res[2]?.payload?.data);
    });
    return () => {
      setActionTrackerReconciliationStatusFilterList([]);
      setActionTrackerActionTakenStatusFilterList([]);
      setActionTrackerActionOwnerStatusFilterList([]);
    };
  }, [dispatch, recoId]);

  let sortId = sort.id;
  let sortDir = sort.direction;

  //First Time rendering-Display All table data
  useEffect(() => {
    dispatch(
      getRecordsToViewActionTrackerRecords({
        recoId: recoId,
        actionStatus: actionTakenStatusforViewActionTracker,
        searchString: searchString,
        actionStatusList: gettingUpdatedActionTakenStatusList,
        recoStatusList: gettingUpdatedReconciliationStatusList,
        actionOwnerList: gettingUpdatedActionOwnerStatusList,
        pageNo: page - 1,
        pageSize: rowsPerPage,
        sortBy: sortId,
        sortDirection: sortDir,
      })
    ).then(() => {
      setLoading(false);
      setopenSelectBox(false);
      setopenReconciliationStatusSelectBox(false);
      setopenActionOwnerFilterBox(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recoId, page, rowsPerPage, sortId, sortDir, refresh, refreshTable]);

  //First Time renders unmatched table data
  useEffect(() => {
    let obj = (viewActionTrackerData?.actionTrackerRecords ?? []).map((val) => {
      return {
        recoId: val?.recoId,
        partyId: val?.partyId,
        dataId: val?.dataId,
        partyCode: val?.partyCode,
        partyName: val?.partyName,
        actionStatus: val?.actionStatus,
        actionOwner: val?.actionOwner,
        pendingSince: val?.pendingSince,
        invoiceDate: val?.invoiceDate,
        invoiceNumber: val?.invoiceNumber,
        invoiceCount: val?.invoiceCount,
        documentType: val?.documentType,
        amount: val?.amount,
        amountNumber: val?.amountNumber,
        status: val?.status,
        classification: val?.classification,
        remarkAvailable: val?.remarkAvailable,
      };
    });
    setRowData(obj);
  }, [viewActionTrackerData]);

  // Handle Select All-Checkbox
  const handleSelectAllClick = (event) => {
    setIsAllSelected(!isAllSelected);
    if (event.target.checked) {
      const newSelectedIds = rowData.map((n) => n.dataId);
      newSelected = newSelectedIds;
      setIsItemSelected(true);
      dispatch(setActionViewTrackerSelected(newSelectedIds));
      return;
    }
    dispatch(setActionViewTrackerSelected([]));
    dispatch(setUnselected([]));
    dispatch(setSelected([]));
    dispatch(setPartyDataIds([]));
    dispatch(setCompanyDataIds([]));

    if (newSelected.length === 0) {
      setIsItemSelected(false);
    }
  };

  //handle Click-Checkbox
  const handleClick = (event, name, Id) => {
    if (isAllSelected) {
      if (!event.target.checked) {
        let result = [];
        result = result.concat(uncheckedDataIds, name);
        dispatch(setUnselected(result));
        return;
      } else {
        const result = uncheckedDataIds.filter((dataId) => dataId !== name);
        dispatch(setUnselected(result));
        return;
      }
    }
    const selectedIndex = actionViewTrackerSelected.indexOf(name);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(actionViewTrackerSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(actionViewTrackerSelected.slice(1));
    } else if (selectedIndex === actionViewTrackerSelected.length - 1) {
      newSelected = newSelected.concat(actionViewTrackerSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        actionViewTrackerSelected.slice(0, selectedIndex),
        actionViewTrackerSelected.slice(selectedIndex + 1)
      );
    }
    dispatch(setActionViewTrackerSelected(newSelected));

    if (newSelected.length > -1) {
      setIsItemSelected(true);
    }
    if (newSelected.length === 0) {
      setIsItemSelected(false);
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const isSelected = (name) => actionViewTrackerSelected.indexOf(name) !== -1;

  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
    cookieAlert: {
      "& .MuiAlert-icon": {
        color: "#ffffff",
      },
    },
  }));

  const classes = useStyles();

  // handle update action status click
  const handleUpdateActionTaken = () => {
    setOpenUpdateActionTaken(true);
  };

  //get value of status from dropdown
  const handleActionStatusChange = (event) => {
    if (
      event.target.value.includes("all") &&
      gettingUpdatedActionTakenStatusList.length !==
        actionTrackerActionTakenStatusFilterList.length
    ) {
      setgettingUpdatedActionTakenStatusList(
        actionTrackerActionTakenStatusFilterList.map(
          // (name) => name.actionStatusValue
          (name) => name
        )
      );
    } else if (event.target.value.includes("all")) {
      setgettingUpdatedActionTakenStatusList([]);
    } else {
      setgettingUpdatedActionTakenStatusList(event.target.value);
    }
  };

  //reconciliation status change
  const handleReconciliationStatusChange = (event) => {
    if (
      event.target.value.includes("all") &&
      gettingUpdatedReconciliationStatusList.length !==
        actionTrackerReconciliationStatusFilterList.length
    ) {
      setgettingUpdatedReconciliationStatusList(
        actionTrackerReconciliationStatusFilterList.map((name) => name)
      );
    } else if (event.target.value.includes("all")) {
      setgettingUpdatedReconciliationStatusList([]);
    } else {
      setgettingUpdatedReconciliationStatusList(event.target.value);
    }
  };

  //action owner status change
  const handleActionOwnerStatusChange = (event) => {
    if (
      event.target.value.includes("all") &&
      gettingUpdatedActionOwnerStatusList.length !==
        actionTrackerActionOwnerStatusFilterList.length
    ) {
      setgettingUpdatedActionOwnerStatusList(
        actionTrackerActionOwnerStatusFilterList.map((name) => name)
      );
    } else if (event.target.value.includes("all")) {
      setgettingUpdatedActionOwnerStatusList([]);
    } else {
      setgettingUpdatedActionOwnerStatusList(event.target.value);
    }
  };

  // handle action taken filter status Apply Button Click
  const handleActionTakensStatusApplyClick = () => {
    setgettingUpdatedReconciliationStatusList([]);
    setgettingUpdatedActionOwnerStatusList([]);
    setRefreshTable(!refreshTable);
  };

  // handle Reconciliation filter status Apply Button Click
  const handleReconciliationStatusApplyClick = () => {
    setgettingUpdatedActionTakenStatusList([]);
    setgettingUpdatedActionOwnerStatusList([]);
    setRefreshTable(!refreshTable);
  };

  // handle action owner status Apply Button Click
  const handleActionOwnerStatusApplyClick = () => {
    setgettingUpdatedReconciliationStatusList([]);
    setgettingUpdatedActionTakenStatusList([]);
    setRefreshTable(!refreshTable);
  };

  // total count of selected records
  const getCount = () => {
    return viewActionTrackerData?.totalItems - uncheckedDataIds.length;
  };

  const getTotalCount = () => {
    if (isAllSelected) {
      return (
        viewActionTrackerData?.totalItems -
        (rowsPerPage - actionViewTrackerSelected.length + 1)
      );
    } else {
      return actionViewTrackerSelected.length;
    }
  };

  //handle view Remark click
  const handleViewRemarkClick = (event, name, amount, source, NewId) => {
    const selectedIndex = selected.indexOf(name);

    if (source === "Party") {
      const selectedIndexPartyId = partyDataIds.indexOf(name); //for partyDataIds array

      if (selectedIndexPartyId === -1) {
        newPartyIdSelected = newPartyIdSelected.concat(partyDataIds, name);
      } else if (selectedIndexPartyId === 0) {
        newPartyIdSelected = newPartyIdSelected.concat(partyDataIds.slice(1));
      } else if (selectedIndexPartyId === partyDataIds.length - 1) {
        newPartyIdSelected = newPartyIdSelected.concat(
          partyDataIds.slice(0, -1)
        );
      } else if (selectedIndexPartyId > 0) {
        newPartyIdSelected = newPartyIdSelected.concat(
          partyDataIds.slice(0, selectedIndexPartyId),
          partyDataIds.slice(selectedIndexPartyId + 1)
        );
      }
      dispatch(setPartyDataIds(newPartyIdSelected));
    } else if (source === "Company") {
      const selectedIndexCompanyId = companyDataIds.indexOf(name); //for companyDataIds array
      if (selectedIndexCompanyId === -1) {
        newCompanyIdSelected = newCompanyIdSelected.concat(
          companyDataIds,
          name
        );
      } else if (selectedIndexCompanyId === 0) {
        newCompanyIdSelected = newCompanyIdSelected.concat(
          companyDataIds.slice(1)
        );
      } else if (selectedIndexCompanyId === companyDataIds.length - 1) {
        newCompanyIdSelected = newCompanyIdSelected.concat(
          companyDataIds.slice(0, -1)
        );
      } else if (selectedIndexCompanyId > 0) {
        newCompanyIdSelected = newCompanyIdSelected.concat(
          companyDataIds.slice(0, selectedIndexCompanyId),
          companyDataIds.slice(selectedIndexCompanyId + 1)
        );
      }
      dispatch(setCompanyDataIds(newCompanyIdSelected));
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    dispatch(setPartyIds(newSelected));
    dispatch(setActionViewTrackerSelected(newSelected));

    if (newSelected.length > -1) {
      setIsItemSelected(true);
    }
    if (newSelected.length === 0) {
      setIsItemSelected(false);
    }

    // setViewRemarkDataId(name);
    dispatch(
      getViewRemarkData({
        recoId,
        partyId: NewId,
        dataId: name,
      })
    ).then(() => {
      setopenActionTrackerAddViewRemark(true);
    });
  };

  const handleArrowClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // download file-More action menu
  const handleDownloadFile = () => {
    dispatch(downloadFileViewActionTrackerTable({ recoId }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //render function for action taken status
  const getSelectActionTakenStatusValues = (selected) => {
    return actionTrackerActionTakenStatusFilterList
      .filter((name) => selected.includes(name.actionStatusCode))
      .map((record) => record.actionStatusCode ?? record.actionStatusValue)
      .join(", ");
  };
  //render function for reconciliation status
  const getSelectReconciliationStatusValues = (selected) => {
    return actionTrackerReconciliationStatusFilterList
      .filter((name) => selected.includes(name.actionStatusCode))
      .map((record) => record.actionStatusCode ?? record.actionStatusValue)
      .join(", ");
  };
  //render value for action owner status
  const getSelectActionOwnerStatusValues = (selected) => {
    return actionTrackerActionOwnerStatusFilterList
      .filter((name) => selected.includes(name.actionOwnerId))
      .map((record) => record.actionOwnerId ?? record.actionOwnerName)
      .join(", ");
  };

  const isChecked = (dataId) => {
    if (!isAllSelected && uncheckedDataIds.length === 0) {
      if (actionViewTrackerSelected.includes(dataId)) {
        return true;
      } else {
        return false;
      }
    } else if (isAllSelected && uncheckedDataIds.includes(dataId)) {
      return false;
    } else if (isAllSelected) {
      return true;
    } else if (actionViewTrackerSelected.includes(dataId)) {
      return true;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: "12px",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={5}>
        <Box display="flex" justifyContent="space-between" gap={2} mt={1}>
          {/* Action Taken Status} */}
          <Box position={"relative"}>
            {/* {gettingUpdatedActionTakenStatusList?.length === 0 && ( */}
            <Typography
              style={{
                position: "absolute",
                left: "15px",
                top: "6px",
                fontFamily: "fontsfree",
              }}
            >
              Action Taken Status
            </Typography>
            {/* )} */}
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              open={openSelectBox}
              onOpen={() => setopenSelectBox(true)}
              onClose={() => setopenSelectBox(false)}
              className="actionfilter_box"
              value={gettingUpdatedActionTakenStatusList}
              displayEmpty
              defaultValue=""
              onChange={handleActionStatusChange}
              IconComponent={FilterIcon}
              // renderValue={(selected) => selected.join(", ")}
              renderValue={getSelectActionTakenStatusValues}
              MenuProps={MenuProps}
              size="small"
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllActionStatusSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllActionStatusSelected}
                    indeterminate={
                      selected.length > 0 &&
                      selected.length <
                        actionTrackerActionTakenStatusFilterList.length
                    }
                    color="success"
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {actionTrackerActionTakenStatusFilterList?.map(
                (option, index) => (
                  <MenuItem key={index} value={option}>
                    <Checkbox
                      checked={
                        gettingUpdatedActionTakenStatusList.indexOf(option) > -1
                      }
                      color="success"
                      size="small"
                    />
                    <ListItemText primary={option.actionStatusValue} />
                  </MenuItem>
                )
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                  gap: 5,
                }}
              >
                <Button
                  variant="text"
                  onClick={() => setopenSelectBox(false)}
                  style={cancelStyle}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  onClick={handleActionTakensStatusApplyClick}
                  style={applybtnStyle}
                >
                  Select
                </Button>
              </Box>
            </Select>
          </Box>

          {/* Reconciliation Status} */}
          <Box position={"relative"}>
            <Typography
              style={{
                position: "absolute",
                left: "15px",
                top: "6px",
                fontFamily: "fontsfree",
              }}
            >
              Reconciliation Status
            </Typography>
            {/* )} */}
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              open={openReconciliationStatusSelectBox}
              onOpen={() => setopenReconciliationStatusSelectBox(true)}
              onClose={() => setopenReconciliationStatusSelectBox(false)}
              className="actionfilter_box"
              value={gettingUpdatedReconciliationStatusList}
              displayEmpty
              defaultValue=""
              onChange={handleReconciliationStatusChange}
              IconComponent={FilterIcon}
              renderValue={getSelectReconciliationStatusValues}
              MenuProps={MenuProps}
              size="small"
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllReconciliationStatusSelected
                    ? classes.selectedAll
                    : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllReconciliationStatusSelected}
                    indeterminate={
                      selected.length > 0 &&
                      selected.length <
                        actionTrackerReconciliationStatusFilterList.length
                    }
                    color="success"
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {actionTrackerReconciliationStatusFilterList?.map(
                (option, index) => (
                  <MenuItem key={index} value={option}>
                    <Checkbox
                      checked={
                        gettingUpdatedReconciliationStatusList.indexOf(option) >
                        -1
                      }
                      color="success"
                      size="small"
                    />
                    <ListItemText primary={option.actionStatusValue} />
                  </MenuItem>
                )
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                  gap: 5,
                }}
              >
                <Button
                  variant="text"
                  onClick={() => setopenReconciliationStatusSelectBox(false)}
                  style={cancelStyle}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  onClick={handleReconciliationStatusApplyClick}
                  style={applybtnStyle}
                >
                  Select
                </Button>
              </Box>
            </Select>
          </Box>

          {/* action owner */}
          <Box position={"relative"}>
            {/* {gettingUpdatedActionOwnerStatusList?.length === 0 && ( */}
            <Typography
              style={{
                position: "absolute",
                left: "15px",
                top: "6px",
                fontFamily: "fontsfree",
              }}
            >
              Action Owner
            </Typography>
            {/* )} */}
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              open={openActionOwnerFilterBox}
              onOpen={() => setopenActionOwnerFilterBox(true)}
              onClose={() => setopenActionOwnerFilterBox(false)}
              className="actionowner_box"
              value={gettingUpdatedActionOwnerStatusList}
              displayEmpty
              defaultValue=""
              onChange={handleActionOwnerStatusChange}
              IconComponent={() => <ArrowDropDownIcon sx={{ color: "#000" }} />}
              // renderValue={(selected) => selected.join(", ")}
              renderValue={getSelectActionOwnerStatusValues}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllActionOwnerStatusSelected
                    ? classes.selectedAll
                    : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllActionOwnerStatusSelected}
                    indeterminate={
                      selected.length > 0 &&
                      selected.length <
                        actionTrackerActionOwnerStatusFilterList.length
                    }
                    color="success"
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {actionTrackerActionOwnerStatusFilterList?.map(
                (option, index) => (
                  <MenuItem key={index} value={option}>
                    <Checkbox
                      checked={
                        gettingUpdatedActionOwnerStatusList.indexOf(option) > -1
                      }
                      color="success"
                      size="small"
                    />
                    <ListItemText primary={option.actionOwnerName} />
                  </MenuItem>
                )
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                  gap: 5,
                }}
              >
                <Button
                  variant="text"
                  onClick={() => setopenActionOwnerFilterBox(false)}
                  style={cancelStyle}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  onClick={handleActionOwnerStatusApplyClick}
                  style={applybtnStyle}
                >
                  Select
                </Button>
              </Box>
            </Select>
          </Box>
        </Box>

        {/* Search and More action */}
        <Box display="flex" justifyContent="space-between" gap={10}>
          <Stack sx={{ mx: "auto" }} direction={"row"} gap={3}>
            {rowData.length === 0 && (
              <Typography fontFamily={"fontsfree"} fontSize={"14px"}>
                No records found.
              </Typography>
            )}
            {rowData.length === 0 && searchString !== "" && (
              <Typography fontFamily={"fontsfree"} fontSize={"14px"}>
                Press <CloseIcon fontSize="14px" /> to clear.
              </Typography>
            )}
          </Stack>
          <Stack
            height={"35px"}
            borderRadius={"18px"}
            border={"1px solid #BBBBBB"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pl={"20px"}
            direction={"row"}
          >
            <input
              className="search-input"
              placeholder="Type and press enter to Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === "") {
                  setSearchString("");
                  setRowsPerPage(10);
                  setPage(1);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchString(e.target.value);
                  setRowsPerPage(10);
                  setPage(1);
                }
              }}
              style={{ width: "220px" }}
            ></input>
            {searchString !== "" ? (
              <CustomTooltip title="Clear" arrow placement="top">
                <IconButton
                  onClick={() => {
                    setSearch("");
                    setSearchString("");
                    setRowsPerPage(10);
                    setPage(1);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </CustomTooltip>
            ) : (
              <div style={{ width: "27px" }}></div>
            )}
            <CustomTooltip title="Search" arrow placement="top">
              <Stack
                height={"35px"}
                width={"35px"}
                borderRadius={"50%"}
                bgcolor={search !== "" ? "#005724" : "#ececec"}
                border={"1px solid #BBBBBB"}
                sx={{ cursor: "pointer" }}
                alignItems={"center"}
                justifyContent={"center"}
                mr={"-2px"}
                onClick={() => {
                  setSearchString(search);
                  setPage(1);
                }}
              >
                <SearchIcon
                  sx={{ color: search === "" ? "#005724" : "#ececec" }}
                />
              </Stack>
            </CustomTooltip>
          </Stack>
          {/* more action} */}
          <Button
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleArrowClick}
            endIcon={<ArrowDropDownOutlinedIcon sx={{ color: "#005724" }} />}
            style={{
              height: "25px",
              fontWeight: "700",
              color: "#000000",
              background: "#ECECEC",
              marginTop: "6px",
            }}
          >
            More Actions
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDownloadFile}>
              <DownloadSummary />
              <Typography fontFamily="fontsfree" marginLeft={2}>
                Download File
              </Typography>
            </MenuItem>
          </StyledMenu>
        </Box>
      </Box>
      <Box className="orangeBoxMatchedTable">
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pl: { sm: 2 },
          }}
        >
          {recoOnOffStatus !== "closed" ? (
            <Box sx={{ marginBottom: "12px", display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                startIcon={
                  <LinkIcon fill={isItemSelected ? "#ececec" : "#005724"} />
                }
                style={isItemSelected ? buttonSelected : buttonSX}
                onClick={
                  isItemSelected ? handleUpdateActionTaken : handleClickSnackbar
                }
              >
                <Typography fontWeight={700}>Update Action Taken</Typography>
              </Button>
              <ActionBulkActions
                isItemSelected={isItemSelected}
                refresh={refresh}
                setRefresh={setRefresh}
                // isAllSelected={isAllSelected}
                setIsItemSelected={setIsItemSelected}
                selectAll={isAllSelected}
                setIsAllSelected={setIsAllSelected}
              />
            </Box>
          ) : (
            <div></div>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              pl: { sm: 2 },
              marginBottom: "12px",
            }}
          >
            {/* Sum Box */}
            <Box className="selectedIemBox">
              <Typography
                fontWeight={700}
                color="#005724"
                fontFamily={"SF Pro Display"}
              >
                Selected
              </Typography>
              <Typography
                fontWeight={700}
                color="#000000"
                fontFamily={"SF Pro Display"}
              >
                {isAllSelected ? getCount() : getTotalCount()}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </Box>
      <Paper style={{ border: "0.5px solid #BBBBBB" }}>
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            stickyHeader
            style={{
              bgcolor: "#FFF",
              overflowX: "scroll",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
            }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              rowCount={rowData.length}
              numSelected={actionViewTrackerSelected.length}
              onSelectAllClick={handleSelectAllClick}
              isAllSelected={isAllSelected}
            />

            <TableBody className="matchedDatatable">
              {rowData?.map((row, index) => {
                const isItemSelected = isSelected(row.dataId, row.partyId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    //selected={isItemSelected}
                    selected={isChecked(row.dataId)}
                  >
                    {/* View Remark Button Code */}
                    <TableCell>
                      {row.remarkAvailable && (
                        <div
                          className="redIconParent"
                          onClick={(event) =>
                            handleViewRemarkClick(
                              event,
                              row.dataId,
                              row.companyAmount,
                              row.source,
                              row.partyId
                            )
                          }
                        >
                          <div className="redCirle"></div>
                          <div className="viewremarkbtn">
                            <div className="redCirleblank"></div>
                            <span>View&nbsp;Remark</span>
                          </div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      padding="none"
                      style={{
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      <Checkbox
                        disableRipple
                        onClick={(event) =>
                          handleClick(
                            event,
                            row.dataId,
                            row.partyId,
                            row.recoId
                          )
                        }
                        color="success"
                        checked={isChecked(row.dataId)}
                        // checked={isChecked(row.dataId)}
                        inputProps={{ "aria-labelledby": labelId }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.partyCode}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.partyName}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          fontSize: "12px",
                          background: "#FA8F8F",
                          // height: "14px",
                          // width: "250px",
                          borderRadius: "5px",
                        }}
                      >
                        {row.actionStatus}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.actionOwner}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.pendingSince}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{ fontSize: "12px" }}
                    >
                      <Box width={"90px"}>{row.invoiceDate}</Box>
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.invoiceCount}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.documentType}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px", textAlign: "right" }}>
                      {row.amount}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.status}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {row.classification}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* toolbar for pagination and row per page */}
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
        style={{ marginLeft: "-12px" }}
      >
        <TextField
          select
          size="small"
          className="page-per-row"
          defaultValue={10}
          onChange={handleChangeRowsPerPage}
          style={{ marginLeft: "0px" }}
          sx={{
            ".MuiSelect-select": {
              fontFamily: "SFProdisplay",
              fontStyle: "normal",
              fontWeight: "700",
              color: "#005724",
            },
            ".MuiSvgIcon-root-ffqUiY": {
              color: "#005724",
            },
          }}
        >
          <MenuItem className="menu-items" value={10}>
            10 per page
          </MenuItem>
          <MenuItem value={25}>25 per page</MenuItem>
          <MenuItem value={50}>50 per page</MenuItem>
          <MenuItem value={100}>100 per page</MenuItem>
        </TextField>

        <Stack
          spacing={2}
          className="pagination-box"
          style={{ marginRight: "0px" }}
        >
          <Pagination
            page={page}
            shape="rounded"
            component="div"
            defaultPage={1}
            count={viewActionTrackerData?.totalPages ?? 1}
            onChange={handleChangePage}
            renderItem={(item) => (
              <PaginationItem
                variant="outlined"
                {...item}
                classes={{
                  root: classes.root,
                  selected: classes.selected,
                }}
              />
            )}
          />
        </Stack>
      </Toolbar>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          className={classes.cookieAlert}
          severity="info"
          sx={{
            width: "100%",
            bgcolor: "rgba(37, 172, 75, 1)",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          Please select atleast one record
        </Alert>
      </Snackbar>
      <ActionTrackerUpdateActionTakenModal
        openUpdateActionTaken={openUpdateActionTaken}
        setOpenUpdateActionTaken={setOpenUpdateActionTaken}
        refresh={refresh}
        setRefresh={setRefresh}
        selectAll={isAllSelected}
        setIsAllSelected={setIsAllSelected}
        setIsItemSelected={setIsItemSelected}
      />
      <ActionTrackerAddViewRemarkModal
        openActionTrackerAddViewRemark={openActionTrackerAddViewRemark}
        setopenActionTrackerAddViewRemark={setopenActionTrackerAddViewRemark}
        refresh={refresh}
        setRefresh={setRefresh}
        setIsItemSelected={setIsItemSelected}
      />
    </Box>
  );
}
export default IsLoadingHOC(ActionViewTrackerTable);
