import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {
  CallBackIcon,
  CloseIcon,
  FileAttachment,
  FileDownloadIcon,
  RedCloseIcon,
  SendQueryIcon,
} from "components/AllSvg";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { downloadDocument, respondQuery } from "store/requeststatement/slice";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#f6f6f6",
  width: "480px",
  scale: "90%",
};

const submit = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

const RespondQuery = (props) => {
  const [firstFile, setFirstFile] = useState([]);
  const [secondFile, setSecondFile] = useState([]);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
    cookieAlert: {
      "& .MuiAlert-icon": {
        color: "#ffffff",
      },
    },
  }));
  const classes = useStyles();

  const dispatch = useDispatch();
  const { queryData } = useSelector((state) => state.partyrecosummary);

  const { recoId, partyId, queryId } = queryData;

  const form = {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "4px",
    boxShadow: "0px 4px 15px 0px #00000040",
    minWidth: "200px",
  };

  const handleClose = () => {
    values.queryResponse = "";
    values.select = "";
    setFirstFile([]);
    setSecondFile([]);
    props.setOpen(false);
  };

  const handleDownloadDoc = (recoId, partyId, doc) => {
    dispatch(downloadDocument({ recoId, partyId, doc }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      queryResponse: "",
      select: "",
    },
    validationSchema:
      queryData?.queryMode === "Send Query"
        ? Yup.object().shape({
            queryResponse: Yup.string().trim().required("Enter Query Details"),
          })
        : Yup.object().shape({
            select: Yup.string().required(),
          }),

    onSubmit: (val) => {
      const queryResponse =
        queryData?.queryMode === "Send Query" ? val.queryResponse : val.select;
      const formData = new FormData();
      [firstFile[0], secondFile[0]].forEach((file) => {
        formData.append("documents", file);
      });
      // formData.append("documents", [firstFile[0],secondFile[0]])
      formData.append(
        "queryresponse",
        JSON.stringify({
          recoId,
          partyId,
          queryId,
          queryResponse,
        })
      );
      dispatch(respondQuery({ data: formData })).then((res) => {
        if (res?.payload?.status === 400) {
          handleClose();
          return;
        }
        setOpenSnack(true);
        props.setRefresh(!props.refresh);
        handleClose();
      });
    },
  });

  const { getInputProps, open } = useDropzone({
    // noClick: true,
    noKeyboard: true,
    multiple: false,
    minSize: 0,
    maxSize: 5242880,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/doc": [],
      "application/pdf": [],
      "application/docx": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: useCallback(
      (acceptedFiles) => {
        setIsFileTooLarge(false);
        if (firstFile.length === 0) {
          setFirstFile(acceptedFiles);
          return;
        }
        if (secondFile.length === 0) {
          setSecondFile(acceptedFiles);
          return;
        }
      },
      [firstFile, secondFile]
    ),
    onDropRejected: useCallback((fileRejections) => {
      setIsFileTooLarge(true);
    }, []),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = formik;

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{ dispaly: 'inlineBlock' }}
      >
        <Box style={style}>
          <Box style={form}>
            <Box paddingX={"19px"} paddingY="14px">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography className="popuptitle" style={{ fontSize: "16px" }}>
                  Respond to Query
                </Typography>
                <IconButton onClick={handleClose} disableRipple>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={6}>
                  <Stack gap={2}>
                    <Typography fontFamily={"SFProdisplay"}>
                      Query Mode
                    </Typography>
                    <Stack
                      padding={"10px"}
                      bgcolor="#F0F0F0"
                      direction={"row"}
                      alignItems="center"
                      gap={2}
                      borderRadius="4.57px"
                    >
                      {queryData?.queryMode === "Send Query" ? (
                        <SendQueryIcon fill={"#005724"} />
                      ) : (
                        <CallBackIcon fill={"#005724"} />
                      )}
                      <Typography fontFamily={"SFProdisplay"}>
                        {queryData?.queryMode ?? ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack gap={2}>
                    <Typography fontFamily={"SFProdisplay"}>Name</Typography>
                    <Stack
                      padding={"10px"}
                      bgcolor="#F0F0F0"
                      direction={"row"}
                      alignItems="center"
                      gap={2}
                      borderRadius="4.57px"
                    >
                      <Typography fontFamily={"SFProdisplay"}>
                        {queryData?.partyUserName ?? ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                {queryData?.contactNumber && (
                  <Grid item xs={12} md={6}>
                    <Stack gap={2}>
                      <Typography fontFamily={"SFProdisplay"}>
                        Mobile Number
                      </Typography>
                      <Stack
                        padding={"10px"}
                        bgcolor="#F0F0F0"
                        direction={"row"}
                        alignItems="center"
                        gap={2}
                        borderRadius="4.57px"
                      >
                        <Typography fontFamily={"SFProdisplay"}>
                          {queryData?.contactNumber ?? ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <Stack gap={2}>
                    <Typography fontFamily={"SFProdisplay"}>
                      Query Details
                    </Typography>
                    <Stack
                      padding={"10px"}
                      bgcolor="#F0F0F0"
                      direction={"row"}
                      alignItems="center"
                      gap={2}
                      borderRadius="4.57px"
                    >
                      <Typography fontFamily={"SFProdisplay"}>
                        {queryData?.queryDetails ?? ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>

                {queryData?.partyDocuments?.length !== 0 && (
                  <Grid item xs={12} md={12}>
                    <Stack gap={2}>
                      <Typography fontFamily={"SFProdisplay"}>
                        File attachement
                      </Typography>

                      <Box
                        border={"1px solid #BBBBBB"}
                        borderRadius="5px"
                        paddingX={"10px"}
                        paddingY="4px"
                      >
                        {(queryData?.partyDocuments ?? []).map(
                          (item, index) => {
                            return (
                              <div key={index}>
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <FileAttachment />
                                  <Typography
                                    ml={4}
                                    fontWeight={700}
                                    fontFamily="fontsfree"
                                    mr={"auto"}
                                    style={{ textDecoration: "underline" }}
                                  >
                                    {/* {selectedFiles.length === 0?'': selectedFiles[0]?.name} */}
                                    {/* {firstFile.length === 0 ? "" : firstFile[0]?.name} */}
                                    {item}
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      handleDownloadDoc(
                                        props.recoId,
                                        props.partyId,
                                        item
                                      );
                                    }}
                                  >
                                    <FileDownloadIcon />
                                  </IconButton>
                                </Box>
                                {queryData?.partyDocuments.length > 1 &&
                                  index !==
                                    queryData?.partyDocuments.length - 1 && (
                                    <Divider></Divider>
                                  )}
                              </div>
                            );
                          }
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box
                bgcolor={"#F0F0F0"}
                paddingX="23px"
                paddingTop={"12px"}
                paddingBottom="17px"
                borderRadius={"0 0 7px 7px"}
              >
                <Typography>
                  Your Response <span className="text-danger">*</span>
                </Typography>
                {queryData?.queryMode === "Send Query" ? (
                  <>
                    <TextareaAutosize
                      minRows={3}
                      style={{
                        border: "1px solid #BBBBBB",
                        borderRadius: "5px",
                        padding: "8px",
                        fontFamily: "SFProdisplay",
                        resize: "vertical",
                        marginTop: "10px",
                        width: "100%",
                      }}
                      placeholder="Start typing your response"
                      value={values.queryResponse}
                      onChange={(e) => {
                        setFieldValue("queryResponse", e.target.value);
                      }}
                      id="queryDetails"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      name="queryDetails"
                    />
                    <span
                      style={{
                        display: "block",
                        color:
                          errors.queryResponse && touched.queryResponse
                            ? "#ff0000"
                            : "#f0f0f0",
                      }}
                    >
                      Enter Query Response
                    </span>
                  </>
                ) : (
                  <>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.select}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        setFieldValue("select", e.target.value);
                      }}
                      sx={{ marginTop: "10px", bgcolor: "#FFF" }}
                    >
                      <MenuItem value={"Query_Resolved_over_call"}>
                        Query Resolved over call
                      </MenuItem>
                      <MenuItem value={"Unable_to_reach"}>
                        Unable to reach
                      </MenuItem>
                    </Select>
                    <span
                      style={{
                        display: "block",
                        color:
                          errors.select && touched.select
                            ? "#ff0000"
                            : "#f0f0f0",
                      }}
                    >
                      Select option
                    </span>
                  </>
                )}
                <Box
                  p={2}
                  mt={2}
                  bgcolor="#FFF"
                  sx={{
                    border: "1px solid #BBBBBB",
                    height: "40px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  // {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <FileAttachment></FileAttachment>
                  <Typography ml={4} fontWeight={700} fontFamily="fontsfree">
                    {/* {selectedFiles.length === 0?'': selectedFiles[0]?.name} */}
                    {firstFile.length === 0 ? "" : firstFile[0]?.name}
                  </Typography>
                  {firstFile.length === 0 ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        open();
                      }}
                      style={{
                        marginLeft: "auto",
                        backgroundColor: "#ececec",
                        color: "#005724",
                        fontWeight: 700,
                        fontFamily: "fontsfree",
                      }}
                    >
                      Browse&nbsp;File
                    </Button>
                  ) : (
                    <Box style={{ marginLeft: "auto" }}>
                      <RedCloseIcon
                        width="15"
                        height="35"
                        cursor="pointer"
                        onClick={() => setFirstFile([])}
                      />
                    </Box>
                  )}
                </Box>
                <Stack direction="column" mt="5px">
                  <Typography
                    mr="auto"
                    fontFamily="SFProdisplay"
                    color="#a7a7a7"
                    fontSize="14px"
                    fontWeight={400}
                  >
                    Acceptable file types : pdf, xls, xlsx, xlsm, csv, doc,
                    docx, jpg, png
                  </Typography>
                  <Typography
                    fontFamily="SFProdisplay"
                    color="#a7a7a7"
                    fontSize="14px"
                    fontWeight={400}
                  >
                    File size : 5 MB
                  </Typography>
                </Stack>

                {isFileTooLarge && (
                  <div style={{ color: "red" }}>File is too large.</div>
                )}

                {secondFile.length === 1 && (
                  <Box
                    p={2}
                    bgcolor="#FFF"
                    sx={{
                      border: "1px solid #BBBBBB",
                      height: "40px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                    // {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <FileAttachment></FileAttachment>

                    <Typography ml={4} fontWeight={700} fontFamily="fontsfree">
                      {secondFile.length === 0 ? "" : secondFile[0]?.name}
                    </Typography>
                    <Box style={{ marginLeft: "auto" }}>
                      <RedCloseIcon
                        width="15"
                        height="35"
                        cursor="pointer"
                        onClick={() => setSecondFile([])}
                      />
                    </Box>
                  </Box>
                )}

                {firstFile.length === 0 || secondFile.length === 1 ? (
                  <Box marginY="30px"></Box>
                ) : (
                  <Typography
                    onClick={open}
                    marginY="8px"
                    ml="auto"
                    color="#005724"
                    fontWeight={700}
                    fontSize="12px"
                    fontFamily="fontsfree"
                    sx={{
                      textDecoration: "underline",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    + Add more
                  </Typography>
                )}
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <Button className="cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button style={submit} type="submit">
                    Submit
                  </Button>
                </Stack>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          className={classes.cookieAlert}
          severity="success"
          sx={{
            width: "100%",
            bgcolor: "rgba(37, 172, 75, 1)",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          Query Raised Sucessfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default RespondQuery;
