const initialValues = {
  error: null,
  ruleGroups: {},
  ruleGrpData: {},
  ruleGroupName: "",
  activeValue: 1,
  preList: [],
  primaryList: [],
  secondaryList: [],
  postList: [],
  ruleGrpId: "",
};

export default initialValues;
