import { createSlice } from "@reduxjs/toolkit";
import state from "./state";

const tokenSlice = createSlice({
  name: "tokenSlice",
  initialState: state,
  reducers: {
    getTokenFromUrl(state, action) {
      var url = window.location;
      var token = new URLSearchParams(url.search).get("token");
      localStorage.setItem("accessToken", `Bearer ${token}`);
      state.token = token;
    },
  },
});

export const { getTokenFromUrl } = tokenSlice.actions;
const { reducer } = tokenSlice;

export default reducer;
