import { Stack, Typography } from "@mui/material";
import NoSftpImg from "components/NoSftpImg";
import React from "react";

const NoStatementCard = () => {
  return (
    <Stack
      bgcolor={"#F3F3F3"}
      padding="16px"
      borderRadius={"7.5px"}
      height={"70vh"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <NoSftpImg />
      <Typography
        fontFamily={"ekmukta-bold"}
        fontSize={"20px"}
        mt={"40px"}
        mb={"6px"}
      >
        No Files received for Transformation
      </Typography>
      <Typography fontFamily={"ekmukta"} fontSize={"12px"} textAlign={"center"}>
        Kindly check if the files have been shared on Firmway Server. <br></br>
        For more details connect with Customer Success Team.
      </Typography>
    </Stack>
  );
};

export default NoStatementCard;
