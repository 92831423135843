import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import {
  CloseIcon,
  DeleteIconSmall,
  RedCloseIcon,
  FileAttachment,
  SamplefileIcon,
  DownloadFileIcon,
} from "components/AllSvg";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttachmentAndCommentForDrawer,
  updateCommentAndFileOfCommentDrawer,
  downloadFileFromCommentDrawer,
  deleteCommentsAndFilesOfCommentDrawer,
} from "store/requeststatement/slice";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";

const loadBtnStyle = {
  width: "105px",
  height: "38.8px",
  borderRadius: "4.27px",
  color: "#005724",
  border: "0.85px solid #FF9A01",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "10px",
};

const commentStyle = {
  fontFamily: "fontsfree",
  fontWeight: 400,
  fontSize: "12px",
  marginBottom: "2px",
};

const submit = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

const cancelStyle = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: "700",
  background: "#FFFFFF",
  color: "#005724",
  border: "1px solid #FF9A01",
  boxSizing: "border-box",
  borderRadius: "5px",
};

const downloadFileStyle = {
  cursor: "pointer",
  width: "22",
  height: "22",
};

const input = {
  ".MuiTextField-root": {
    padding: "0px",
    bgcolor: "red",
  },
};

const button = {
  width: "92px",
  height: "26px",
  borderRadius: "3.25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const ActionTrackerCommentDrawer = ({
  openCommentDrawer,
  setopenCommentDrawer,
  setRefresh,
  refresh,
  drawerRefresh,
}) => {
  const { selectedInfo, attchementCommentForDrawerComments } = useSelector(
    (state) => state.trackreconciliation
  );

  const [openModal, setOpenModal] = useState(false);
  const [firstFile, setFirstFile] = useState([]);
  const [secondFile, setSecondFile] = useState([]);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [fileValidation] = useState(false);
  const [setReload] = useState(false);
  const [selectedcommentId, setselectedCommentId] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: "",
    },

    onSubmit: (val) => {
      setIsFileTooLarge(false);
      const formData = new FormData();
      [firstFile[0], secondFile[0]].forEach((file) => {
        formData.append("statements", file);
      });

      formData.append(
        "attachmentrequest",
        JSON.stringify({
          ...val,
          recoId: selectedInfo?.recoId,
          partyCode: selectedInfo?.partyCode,
          summaryCode: selectedInfo?.summaryCode,
        })
      );

      //Post API call to submit file and comment
      dispatch(updateCommentAndFileOfCommentDrawer({ data: formData })).then(
        () => {
          setFirstFile([]);
          setSecondFile([]);

          dispatch(
            getAttachmentAndCommentForDrawer({
              recoId: selectedInfo?.recoId,
              partyCode: selectedInfo?.partyCode,
              summaryCode: selectedInfo?.summaryCode,
              pageNo: pageNo,
            })
          ).then((res) => {});
        }
      );
      resetForm({ comment: "" });
    },
  });

  const { getInputProps, open } = useDropzone({
    noKeyboard: true,
    multiple: false,
    minSize: 0,
    maxSize: 5242880,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/doc": [],
      "application/pdf": [],
      "application/docx": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: useCallback(
      (acceptedFiles) => {
        setIsFileTooLarge(false);
        if (firstFile.length === 0) {
          setFirstFile(acceptedFiles);
          return;
        }
        if (secondFile.length === 0) {
          setSecondFile(acceptedFiles);
          return;
        }
      },
      [firstFile, secondFile]
    ),
    onDropRejected: useCallback((fileRejections) => {
      setIsFileTooLarge(true);
    }, []),
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    resetForm,
  } = formik;

  //to get comments to show on comment drawer
  useEffect(() => {
    dispatch(
      getAttachmentAndCommentForDrawer({
        recoId: selectedInfo?.recoId,
        partyCode: selectedInfo?.partyCode,
        summaryCode: selectedInfo?.summaryCode,
        pageNo: pageNo,
      })
    ).then((res) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInfo?.summaryCode, selectedcommentId, pageNo, drawerRefresh]);

  //close comment drawer panel/model
  const handleClose = () => {
    setPageNo(0);
    setRefresh(!refresh);
    setopenCommentDrawer(false);
    setReload(false);
  };

  //close delete popup model box
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#DBFFEA",
        color: "#005724",
        fontFamily: "fontsfree",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  //cancel button click
  const handleCancel = () => {
    setFirstFile([]);
    setSecondFile([]);
  };

  //to Download file-More action menu
  const handleFileDownloadClick = (val) => {
    dispatch(
      downloadFileFromCommentDrawer({
        recoId: selectedInfo?.recoId,
        fileName: val?.attachmentName,
      })
    );
  };

  //to delete file/comment
  const handleDeleteComment = () => {
    dispatch(
      deleteCommentsAndFilesOfCommentDrawer({
        recoId: selectedInfo?.recoId,
        partyCode: selectedInfo?.partyCode,
        summaryCode: selectedInfo?.summaryCode,
        commentId: selectedcommentId,
      })
    ).then(() => {
      setOpenModal(false);
      setRefresh(!refresh);
      setselectedCommentId("");
    });
  };

  //handle Load more button click
  const handleLoadMore = () => {
    setPageNo(pageNo + 1); //to increase page size by 1
  };

  return (
    <>
      <Drawer anchor="right" open={openCommentDrawer} onClose={handleClose}>
        <Box p="20px" width="28vw" minWidth={380} role="presentation">
          {/* Heading */}
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Typography
              fontFamily="fontsfree"
              fontSize="16px"
              fontWeight={800}
              color="#005724"
            >
              Attachments & Comments
            </Typography>
            <IconButton onClick={handleClose} disableRipple>
              <CloseIcon />
            </IconButton>
          </Stack>
          {/* Party data */}
          <form onSubmit={handleSubmit}>
            <Stack bgcolor="#F4F4F4" mb={2} padding={1}>
              <Grid container>
                <Grid item xs={4} md={3}>
                  <Typography fontFamily="SFProdisplay" fontSize="12px">
                    Party Code
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography fontFamily="fontsfree" fontSize="14px">
                    {selectedInfo?.partyCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}></Grid>

                <Grid item xs={6} md={3}>
                  <Typography fontFamily="SFProdisplay" fontSize="12px">
                    Party Name
                  </Typography>
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography fontFamily="fontsfree" fontSize="14px">
                    {selectedInfo?.partyName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}></Grid>

                <Grid item xs={6} md={3}>
                  <Typography fontFamily="SFProdisplay" fontSize="12px">
                    Particular
                  </Typography>
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography fontFamily="fontsfree" fontSize="14px">
                    {selectedInfo?.summaryName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
              </Grid>
            </Stack>
            {/* comment Box */}
            <label>
              <span style={commentStyle}> Comment</span>
              <span className="text-danger">*</span>
            </label>
            <Stack direction="row" gap={2}>
              <TextField
                name="comment"
                id="comment"
                placeholder="Add comment"
                size="small"
                variant="outlined"
                sx={input}
                multiline
                rows={3}
                fullWidth
                autoComplete="off"
                onBlur={handleBlur}
                value={values.comment}
                onChange={(event) => {
                  setFieldValue("comment", event.target.value);
                }}
              />
            </Stack>
            {/* {commentErrorMsg} */}
            {errors.comment && touched.comment ? (
              <span className="text-danger">{errors.comment}</span>
            ) : null}

            {/* file attachment */}
            <Box>
              <Box
                p={2}
                mt={3}
                sx={{
                  border: "1px solid #BBBBBB",
                  height: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
                mb={2}
                // {...getRootProps()}
              >
                <input {...getInputProps()} />
                <FileAttachment></FileAttachment>
                <Typography ml={4} fontWeight={700} fontFamily="fontsfree">
                  {firstFile.length === 0 ? "" : firstFile[0]?.name}
                </Typography>
                {firstFile.length === 0 ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={open}
                    style={{
                      marginLeft: "auto",
                      color: "#005724",
                      fontWeight: 700,
                      fontFamily: "fontsfree",
                      background: "#FFFFFF",
                      border: "1px solid #FF9A01",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                      width: "100px",
                      height: "30px",
                      fontSize: "12px",
                    }}
                  >
                    Browse&nbsp;File
                  </Button>
                ) : (
                  <Box style={{ marginLeft: "auto" }}>
                    <RedCloseIcon
                      width="15"
                      height="35"
                      cursor="pointer"
                      onClick={() => setFirstFile([])}
                    />
                  </Box>
                )}
              </Box>
              {fileValidation && (
                <Typography color={"#FF0000"}>
                  Please Upload Attachment
                </Typography>
              )}
              <Stack direction="row" mt="5px">
                <Typography
                  mr="auto"
                  fontFamily="SFProdisplay"
                  color="#a7a7a7"
                  fontSize="10px"
                  fontWeight={400}
                >
                  Acceptable file types : pdf, xls, xlsx, xlsm, csv, doc, docx,
                  jpg, jpeg, png
                </Typography>
                <Typography
                  fontFamily="SFProdisplay"
                  color="#a7a7a7"
                  fontSize="10px"
                  fontWeight={400}
                >
                  File size : 5 MB
                </Typography>
              </Stack>

              {isFileTooLarge && (
                <div style={{ color: "red" }}>File is too large.</div>
              )}

              {secondFile.length === 1 && (
                <Box
                  p={2}
                  sx={{
                    border: "1px solid #BBBBBB",
                    height: "40px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <input {...getInputProps()} />
                  <FileAttachment></FileAttachment>

                  <Typography ml={4} fontWeight={700} fontFamily="fontsfree">
                    {secondFile.length === 0 ? "" : secondFile[0]?.name}
                  </Typography>
                  <Box style={{ marginLeft: "auto" }}>
                    <RedCloseIcon
                      width="15"
                      height="35"
                      cursor="pointer"
                      onClick={() => setSecondFile([])}
                    />
                  </Box>
                </Box>
              )}

              {firstFile.length === 0 || secondFile.length === 1 ? (
                <Box marginY="30px"></Box>
              ) : (
                <Typography
                  onClick={open}
                  marginY="8px"
                  ml="auto"
                  color="#005724"
                  fontWeight={700}
                  fontSize="12px"
                  fontFamily="fontsfree"
                  sx={{
                    textDecoration: "underline",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  + Add more
                </Typography>
              )}
            </Box>

            <Stack
              spacing={2}
              direction="row"
              marginTop={2}
              justifyContent="flex-end"
              mb={4}
            >
              <Button style={cancelStyle} onClick={handleCancel}>
                Cancel
              </Button>
              <Button style={submit} type="submit">
                Submit
              </Button>
            </Stack>
          </form>
          <Divider />
          {attchementCommentForDrawerComments?.length > 0 &&
            attchementCommentForDrawerComments?.map((item, index) => {
              const name = item.userName === null ? "N A" : item.userName;

              return (
                <Box key={index}>
                  <Stack direction="row" alignItems="center" marginTop="16px">
                    <Avatar {...stringAvatar(name)} />
                    <Stack marginLeft="12px">
                      <Typography fontFamily="fontsfree">{name}</Typography>
                      <Typography
                        fontFamily="SFProdisplay"
                        color="#6f6f6f"
                        fontSize="12px"
                      >
                        {item.entityType}
                      </Typography>
                    </Stack>
                    <Box
                      marginLeft="auto"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenModal(true);
                        setselectedCommentId(item?.commentId);
                      }}
                    >
                      <DeleteIconSmall />
                    </Box>
                  </Stack>
                  <Typography
                    fontFamily="SFProdisplay"
                    fontSize="12px"
                    marginTop="12px"
                    mb="8px"
                  >
                    {item.comment}
                  </Typography>
                  {item?.attachments?.map((val) => {
                    return (
                      <>
                        <Stack
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Box
                            pl="2px"
                            pr="5px"
                            py="1px"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap="10px"
                            border="1px solid #F3F3F3"
                            borderRadius="5px"
                            mb="3px"
                          >
                            <SamplefileIcon width="22" height="22" />
                            <Typography fontWeight="bold">
                              {val?.attachmentDisplayName}
                            </Typography>
                            <Button
                              onClick={() => handleFileDownloadClick(val)}
                            >
                              <DownloadFileIcon style={downloadFileStyle} />
                            </Button>
                          </Box>
                          <Box mt="2px">
                            {moment(item.addedAt).format("DD-MMM-YY LT")}
                          </Box>
                        </Stack>
                      </>
                    );
                  })}
                  <Divider />
                </Box>
              );
            })}
          <Button onClick={handleLoadMore} style={loadBtnStyle}>
            Load More
          </Button>
        </Box>
        {/* delete modal box for delete comment */}
        <Modal open={openModal} onClose={handleCloseModal} hideBackdrop={true}>
          <Box
            width="28vw"
            minWidth={380}
            height="150px"
            bgcolor="rgba(0, 0, 0, 0.75)"
            position="absolute"
            top="40%"
            right="0%"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography fontFamily="fontsfree" color="#FFF" fontSize="20px">
              Delete Comment
            </Typography>
            <Typography
              fontFamily="SFProdisplay"
              color="#FFF"
              fontSize="12px"
              marginY="10px"
            >
              Are you sure you want to permanently delete this comment?
            </Typography>
            <Stack marginX="auto" direction={"row"} gap={2}>
              <Box
                sx={button}
                bgcolor="#FFF"
                border="1px solid #FF9A01"
                onClick={handleCloseModal}
              >
                <Typography fontFamily="fontsfree" color="#005724">
                  No
                </Typography>
              </Box>
              <Box
                sx={button}
                bgcolor="#FF3C3C"
                border="1px solid #FF3C3C"
                onClick={handleDeleteComment}
              >
                <Typography fontFamily="fontsfree" color="#FFF">
                  Yes
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </Drawer>
    </>
  );
};

export default ActionTrackerCommentDrawer;
