import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import state from "./state";
import axiosApi from "helper/axiosApi";

//to get frequency type dropdown
export const getFrequencyType = createAsyncThunk(
  "getFrequencyType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/scheduler/api/v1/schedulers/masterdata/scheduler-frequency`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get frequency type dropdown
export const getSchedulerRepeat = createAsyncThunk(
  "getSchedulerRepeat",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/scheduler/api/v1/schedulers/masterdata/scheduler-repeat`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get all schedulers
export const getAllSchedulers = createAsyncThunk(
  "getAllSchedulers",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/scheduler/api/v1/schedulers/all/by-company`
      );
      return res.data.schedulers;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Create scheduler
export const createScheduler = createAsyncThunk(
  "createScheduler",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(`/scheduler/api/v1/schedulers`, data);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Activate Deactivate scheduler
export const activateScheduler = createAsyncThunk(
  "activateScheduler",
  async ({ active, schedulerId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/scheduler/api/v1/schedulers/${schedulerId}/${
          active ? "deactivate" : "activate"
        }`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Activate Deactivate scheduler
export const deleteScheduler = createAsyncThunk(
  "deleteScheduler",
  async ({ schedulerId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.delete(
        `/scheduler/api/v1/schedulers/${schedulerId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get scheduler by Id
export const getSchedulersById = createAsyncThunk(
  "getSchedulersById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/scheduler/api/v1/schedulers/${id}`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Create scheduler
export const updateScheduler = createAsyncThunk(
  "updateScheduler",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/scheduler/api/v1/schedulers/${id}`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const schedulerSlice = createSlice({
  name: "schedulerSlice",
  initialState: state,
  extraReducers: {
    [createScheduler.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CREATE_SCHEDULER";
    },
    [createScheduler.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CREATE_SCHEDULER";
    },
    [createScheduler.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CREATE_SCHEDULER";
      state.error = action.payload;
    },
    [getFrequencyType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_FREQUENCY_TYPE";
    },
    [getFrequencyType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_FREQUENCY_TYPE";
      state.frequencyType = action.payload;
    },
    [getFrequencyType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_FREQUENCY_TYPE";
      state.error = action.payload;
    },
    [getAllSchedulers.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_SCHEDULERS";
    },
    [getAllSchedulers.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_SCHEDULERS";
      state.schedulers = action.payload;
    },
    [getAllSchedulers.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_SCHEDULERS";
      state.error = action.payload;
    },
    [getSchedulerRepeat.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SCHEDULER_REPEAT";
    },
    [getSchedulerRepeat.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SCHEDULER_REPEAT";
    },
    [getSchedulerRepeat.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SCHEDULER_REPEAT";
      state.error = action.payload;
    },
    [activateScheduler.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ACTIVATE_SCHEDULER";
    },
    [activateScheduler.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ACTIVATE_SCHEDULER";
    },
    [activateScheduler.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ACTIVATE_SCHEDULER";
      state.error = action.payload;
    },
    [deleteScheduler.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DELETE_SCHEDULER";
    },
    [deleteScheduler.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DELETE_SCHEDULER";
    },
    [deleteScheduler.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DELETE_SCHEDULER";
      state.error = action.payload;
    },
    [getSchedulersById.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SCHEDULER_BY_ID";
    },
    [getSchedulersById.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SCHEDULER_BY_ID";
      state.configData = action.payload;
    },
    [getSchedulersById.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SCHEDULER_BY_ID";
      state.error = action.payload;
    },
    [updateScheduler.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_SCHEDULER";
    },
    [updateScheduler.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_SCHEDULER";
    },
    [updateScheduler.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_SCHEDULER";
      state.error = action.payload;
    },
  },
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setBack(state, action) {
      state.activeTab = state.activeTab - 1;
    },
    setConfigData(state, action) {
      state.configData = action.payload;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
  },
});

export const { setActiveTab, setBack, setConfigData, setTitle } =
  schedulerSlice.actions;

const { reducer } = schedulerSlice;

export default reducer;
