import { Box, Button } from "@mui/material";
import Lottie from "lottie-react";
import React from "react";
import loading2 from "assets/images/gif/loading2.json";
const enableBtnStyle = {
  width: "130px",
  height: "35px",
  background: "#005724",
  borderRadius: "5px",
};

const DummyButton = () => {
  return (
    <Button
      type="submit"
      variant="contained"
      style={enableBtnStyle}
      sx={{ mr: "10px" }}
    >
      Please wait{" "}
      <Box height={"24px"} width={"24px"}>
        <Lottie animationData={loading2} loop={true} />
      </Box>
    </Button>
  );
};

export default DummyButton;
