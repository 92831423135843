import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CloseIcon, FileAttachment, RedCloseIcon } from "components/AllSvg";
import { Grid, IconButton, Stack, TextField } from "@mui/material";
import "assets/styles/reviewdone.css";
import { useDispatch, useSelector } from "react-redux";
import { attachSignOff } from "store/requeststatement/slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDropzone } from "react-dropzone";
import { useRef } from "react";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#f6f6f6",
  maxWidth: "40%",
  scale: "90%",
};

const submit = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

const AttachSignOff = (props) => {
  const [comment, setComment] = useState("");
  const [firstFile, setFirstFile] = useState([]);
  const [secondFile, setSecondFile] = useState([]);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [fileValidation, setFileValidation] = useState(false);

  const { recoId } = useSelector((state) => state.trackreconciliation);
  const dispatch = useDispatch();

  // For date
  const signOffDateRef = useRef(null);
  const [firstSlashIndexSetFrom, setFirstSlashIndexSetFrom] = useState(false);
  const [secondSlashIndexSetFrom, setSecondSlashIndexSetFrom] = useState(false);

  const form = {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "15px",
    boxShadow: "0px 4px 15px 0px #00000040",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partyPersonName: "",
      signOffdate: null,
      comment: "",
    },
    validationSchema: Yup.object().shape({
      partyPersonName: Yup.string().trim().required("Enter person name"),
      signOffdate: Yup.date().nullable().required("Select date"),
    }),

    onSubmit: (val) => {
      if (firstFile.length === 0) {
        setFileValidation(true);
        return;
      }
      const formData = new FormData();
      [firstFile[0], secondFile[0]].forEach((file) => {
        formData.append("documents", file);
      });
      // formData.append("documents", [firstFile[0],secondFile[0]])
      formData.append(
        "attachsignoff",
        JSON.stringify({
          ...val,
          comment,
        })
      );
      dispatch(
        attachSignOff({
          recoId,
          partyId: props.partyInfo.id,
          data: formData,
        })
      ).then(() => {
        props.setRefresh(!props.refresh);
        handleClose();
      });
    },
  });

  const { getInputProps, open } = useDropzone({
    // noClick: true,
    noKeyboard: true,
    multiple: false,
    minSize: 0,
    maxSize: 5242880,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/doc": [],
      "application/pdf": [],
      "application/docx": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: useCallback(
      (acceptedFiles) => {
        setIsFileTooLarge(false);
        if (firstFile.length === 0) {
          setFirstFile(acceptedFiles);
          return;
        }
        if (secondFile.length === 0) {
          setSecondFile(acceptedFiles);
          return;
        }
      },
      [firstFile, secondFile]
    ),
    onDropRejected: useCallback((fileRejections) => {
      setIsFileTooLarge(true);
    }, []),
  });

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    formik;

  const handleClose = () => {
    props.setOpen(false);
    setFirstFile([]);
    setSecondFile([]);
    setComment("");
    values.signOffdate = "";
    values.partyPersonName = "";
  };

  /**
   * @purpose : Handle date change
   * @param {*} field 
   * @param {*} event 
   */
  const handleDateChange = (field, event) => {
    const value = event || "";

    // Set Field Value
    setFieldValue(field, event);

    // Reset the states if the value is cleared
    if (value === "") {
      setFirstSlashIndexSetFrom(false);
      setSecondSlashIndexSetFrom(false);
    }

    // Allow input value to be updated first before setting the cursor
    setTimeout(() => {
      const inputRef = signOffDateRef;
      const firstSlashSet = firstSlashIndexSetFrom;
      const secondSlashSet = secondSlashIndexSetFrom;

      if (inputRef.current) {
        const inputValue = inputRef.current.value;

        // Find indexes of slashes
        const firstSlashIndex = inputValue.indexOf('/');
        const secondSlashIndex = inputValue.indexOf('/', firstSlashIndex + 1);

        // If date is "dd" completed, move cursor after first slash
        if (firstSlashIndex !== -1 && firstSlashIndex === 2 && !firstSlashSet) {
          setFirstSlashIndexSetFrom(true);
          inputRef.current.setSelectionRange(firstSlashIndex + 1, firstSlashIndex + 1);
        }

        // If date is "dd/MM" completed, move cursor after second slash
        if (secondSlashIndex !== -1 && secondSlashIndex === 5 && !secondSlashSet) {
          setSecondSlashIndexSetFrom(true)
          inputRef.current.setSelectionRange(secondSlashIndex + 1, secondSlashIndex + 1);
        }

        // Move cursor after the year if full date is entered
        if (inputValue.length === 10) {
          inputRef.current.setSelectionRange(inputValue.length, inputValue.length);
        }
      }
    }, 0);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    // style={{ dispaly: 'inlineBlock' }}
    >
      <Box sx={style}>
        <Stack direction="row" m={4} spacing={4}>
          <Typography>Party Code</Typography>
          <Typography fontWeight={700}>{props?.partyInfo?.code}</Typography>
          <Typography>Party Name</Typography>
          <Typography fontWeight={700}>{props?.partyInfo?.name}</Typography>
        </Stack>

        <form onSubmit={handleSubmit}>
          <Box sx={form}>
            <Stack direction="row" justifyContent="space-between">
              <Typography className="popuptitle">Attach Sign Off</Typography>
              <IconButton onClick={handleClose} disableRipple>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <label>
                    <span>Name of the person</span>
                    <span className="text-danger">*</span>
                  </label>
                  <TextField
                    placeholder="Name of the Person"
                    id="partyPersonName"
                    autoComplete="off"
                    name="partyPersonName"
                    value={values.partyPersonName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("partyPersonName", e.target.value);
                    }}
                    size="small"
                  />

                  <span
                    style={{
                      color:
                        errors.partyPersonName && touched.partyPersonName
                          ? "#ff0000"
                          : "#ffffff",
                    }}
                  >
                    Enter person name
                  </span>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <label style={{ display: "block" }}>
                    <span>Date off Sign Off</span>
                    <span className="text-danger">*</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      disableHighlightToday
                      value={values.signOffdate}
                      onBlur={handleBlur}
                      onChange={(event) =>
                        handleDateChange('signOffdate', event)
                      }
                      views={["year", "month", "day"]}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/yyyy",
                            ref: signOffDateRef,
                          }}
                          size="small"
                          id="signOffDate"
                          name="signOffDate"
                          sx={{ background: "#FFFFFF" }}
                          autoComplete="off"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errors.signOffdate && touched.signOffdate ? (
                    <span className="text-danger">{errors.signOffdate}</span>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Box
                    p={2}
                    mt={2}
                    sx={{
                      border: "1px solid #BBBBBB",
                      height: "40px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  // {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <FileAttachment></FileAttachment>
                    <Typography ml={4} fontWeight={700} fontFamily="fontsfree">
                      {/* {selectedFiles.length === 0?'': selectedFiles[0]?.name} */}
                      {firstFile.length === 0 ? "" : firstFile[0]?.name}
                    </Typography>
                    {firstFile.length === 0 ? (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={open}
                        style={{
                          marginLeft: "auto",
                          backgroundColor: "#ececec",
                          color: "#005724",
                          fontWeight: 700,
                          fontFamily: "fontsfree",
                        }}
                      >
                        Browse&nbsp;File
                      </Button>
                    ) : (
                      <Box style={{ marginLeft: "auto" }}>
                        <RedCloseIcon
                          width="15"
                          height="35"
                          cursor="pointer"
                          onClick={() => setFirstFile([])}
                        />
                      </Box>
                    )}
                  </Box>
                  {fileValidation && (
                    <Typography color={"#FF0000"}>
                      Please Upload Attachment
                    </Typography>
                  )}
                  <Stack direction="row" mt="5px">
                    <Typography
                      mr="auto"
                      fontFamily="SFProdisplay"
                      color="#a7a7a7"
                      fontSize="10px"
                      fontWeight={400}
                    >
                      Acceptable file types : pdf, xls, xlsx, xlsm, csv, doc,
                      docx, jpg, png
                    </Typography>
                    <Typography
                      fontFamily="SFProdisplay"
                      color="#a7a7a7"
                      fontSize="10px"
                      fontWeight={400}
                    >
                      File size : 5 MB
                    </Typography>
                  </Stack>

                  {isFileTooLarge && (
                    <div style={{ color: "red" }}>File is too large.</div>
                  )}

                  {secondFile.length === 1 && (
                    <Box
                      p={2}
                      sx={{
                        border: "1px solid #BBBBBB",
                        height: "40px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    // {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <FileAttachment></FileAttachment>

                      <Typography
                        ml={4}
                        fontWeight={700}
                        fontFamily="fontsfree"
                      >
                        {secondFile.length === 0 ? "" : secondFile[0]?.name}
                      </Typography>
                      <Box style={{ marginLeft: "auto" }}>
                        <RedCloseIcon
                          width="15"
                          height="35"
                          cursor="pointer"
                          onClick={() => setSecondFile([])}
                        />
                      </Box>
                    </Box>
                  )}

                  {firstFile.length === 0 || secondFile.length === 1 ? (
                    <Box marginY="30px"></Box>
                  ) : (
                    <Typography
                      onClick={open}
                      marginY="8px"
                      ml="auto"
                      color="#005724"
                      fontWeight={700}
                      fontSize="12px"
                      fontFamily="fontsfree"
                      sx={{
                        textDecoration: "underline",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      + Add more
                    </Typography>
                  )}

                  <Typography>Comment (if any)</Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    id="comment"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    name="comment"
                  />
                  <Stack
                    spacing={2}
                    direction="row"
                    marginTop={2}
                    justifyContent="flex-end"
                  >
                    <Button className="cancel" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button style={submit} type="submit">
                      Submit
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AttachSignOff;
