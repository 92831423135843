import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosPartyApi = axios.create({
  baseURL: baseURL,
  headers: {
    common: {
      Authorization: localStorage.getItem("partyToken"),
    },
  },
});

export default axiosPartyApi;
