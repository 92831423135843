import { Box, Stack, Typography } from "@mui/material";
import { BagIcon, ClockIcon, PersonIcon } from "components/AllSvg";
import { IpIcon, PhoneIcon } from "components/AllSvg2";

const UserDetailsCard = ({ icon, info, pending, title, pendingIcon }) => {
  return (
    <Box
      bgcolor={"#FFF"}
      borderRadius="7.5px"
      padding={info?.name ? "0px" : "12px"}
      width={"max-content"}
    >
      {info?.name ? (
        <Stack direction={"row"}>
          <Box
            bgcolor={"#BEF3D4"}
            width="50px"
            // height={"100%"}
            paddingTop={"16px"}
            textAlign="center"
            style={{
              borderTopLeftRadius: "7.5px",
              borderBottomLeftRadius: "7.5px",
            }}
          >
            {icon}
          </Box>
          <Stack
            paddingLeft={"16px"}
            paddingRight="36px"
            paddingY="16px"
            justifyContent={"space-between"}
            gap={2}
          >
            <Stack direction={"row"}>
              <PersonIcon />
              <Stack marginLeft={"20px"}>
                <Typography fontFamily={"fontsfree"} fontSize="15px">
                  {title}
                </Typography>
                <Typography fontSize="20px" fontFamily={"Square Peg"}>
                  {info?.name}
                </Typography>
                <Typography fontFamily={"fontsfree"} fontSize="15px">
                  <em>{info?.designation ? info?.designation : "-"}</em>
                </Typography>
                <Typography fontSize="15px">
                  {info?.email ? info?.email : "-"}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <BagIcon />
              <Stack marginLeft={"20px"}>
                <Typography fontSize="15px">{info?.companyName}</Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <PhoneIcon />
              <Stack marginLeft={"20px"}>
                <Typography fontSize="15px">-</Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <IpIcon />
              <Stack marginLeft={"20px"}>
                <Typography fontSize="15px">-</Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <ClockIcon />
              <Stack marginLeft={"20px"}>
                <Typography fontSize="15px">{info?.updatedAt}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Box
          bgcolor={"#F2F2F2"}
          paddingX={"120px"}
          height="210px"
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          borderRadius={"8px"}
        >
          <Stack alignItems={"center"}>
            {pendingIcon}
            <Typography
              fontFamily={"fontsfree"}
              color="#B1B1B1"
              fontSize={"15px"}
            >
              {pending}
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default UserDetailsCard;
