import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

import ReduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => ReduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );

  return (
    <>
      <Box marginTop={0}>{childRoutes}</Box>
    </>
  );
};

export default SidebarNavList;
