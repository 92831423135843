const initialValues = {
  sftpList: [],
  sftpSearchedList: [],
  sftpSearchedListSingleParty: [],
  error: null,
  sftp: false,
  company_code: "",
};

export default initialValues;
